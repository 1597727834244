<template>
  <div class="editQuestionInfo">
    <el-dialog
      :title="indexGroup.groupId ? '编辑分卷' : '添加分卷'"
      :visible.sync="dialogVisible"
      :append-to-body="true"
      width="424px"
      :close-on-click-modal="false"
    >
      <el-form
        ref="indexGroup"
        :model="indexGroup"
        label-width="60px"
        :rules="rules"
        :hide-required-asterisk="true"
      >
        <el-form-item label="标题：" prop="groupTitle">
          <el-input v-model="indexGroup.groupTitle"> </el-input>
        </el-form-item>
        <div class="item-box">
          <div class="label">格式：</div>
          <el-select v-model="titleObj.textAlign" placeholder="请选择">
            <el-option label="居左" value="left"> </el-option>
            <el-option label="居中" value="center"> </el-option>
            <el-option label="居右" value="right"> </el-option>
          </el-select>
          <!-- <el-select v-model="titleObj.verticalAlign" placeholder="请选择">
            <el-option label="顶端对齐" value="1"> </el-option>
            <el-option label="垂直居中" value="2"> </el-option>
            <el-option label="底部对齐" value="3"> </el-option>
          </el-select> -->
          <el-select v-model="titleObj.fontSize" placeholder="请选择">
            <el-option
              v-for="(item, index) in sizeItem"
              :key="index"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
          <el-select v-model="titleObj.fontWeight" placeholder="请选择">
            <el-option label="加粗" value="900"> </el-option>
            <el-option label="常规" value="500"> </el-option>
          </el-select>
        </div>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" :loading="btnLoading" @click="submitFn()">
          确 定
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { getStore } from "@/core/util/store";

import {
  updateGroup,
  updateQuestionList,
  saveGroup,
} from "@/core/api/homework/sheet";
export default {
  name: "EditQuestionInfo",
  data() {
    return {
      sizeItem: [],
      dialogVisible: false,
      btnLoading: false,
      scoreAll: 0,
      indexGroup: {},

      titleObj: {
        textAlign: "left",
        // verticalAlign: "1",
        fontSize: 16,
        fontWeight: "500",
      },
      rules: {
        groupTitle: [
          { required: true, message: "请输入标题", trigger: "blur" },
          {
            min: 1,
            max: 50,
            message: "长度在 1 到 50 个字符",
            trigger: "blur",
          },
        ],
      },
    };
  },
  created() {
    this.sizeItem = [];
    for (let i = 0; i < 10; i++) {
      this.sizeItem.push({
        value: 16 + i * 2,
        label: 16 + i * 2,
      });
    }
  },
  methods: {
    // 修改分组  单个情况isShow改为1修改
    async subUpdateGroup(data, type) {
      this.btnLoading = true;
      await updateGroup(data)
        .then(() => {
          if (type == "add") {
            this.$message({
              showClose: true,
              message: "添加成功!",
              type: "success",
            });
            this.editQuestion(data);
            this.btnLoading = false;
          }
        })
        .catch(() => {
          this.btnLoading = false;
        });
    },
    getInfo(titleObj) {
      let str = `<div style="text-align:${titleObj.textAlign};font-size:${titleObj.fontSize}px;font-weight:${titleObj.fontWeight};line-height:1.5">${this.indexGroup.groupTitle}</div>`;
      return str;
    },
    async subSaveGroup(questionList) {
      // console.log("111111111");
      let titleObj = JSON.parse(JSON.stringify(this.titleObj));
      titleObj.cnt = this.getInfo(titleObj);
      this.btnLoading = true;
      let data = {
        sheetUuid: this.$route.query.uuid,
        groupKey: new Date().getTime(),
        groupPriority: questionList.length + 1,
        groupExtInfo: JSON.stringify(titleObj),
        groupType: 1,
      };

      Object.assign(data, this.indexGroup);
      await saveGroup(data)
        .then((res) => {
          this.$message({
            showClose: true,
            message: "已成功添加至最后一级,长按文字可以拖动排序!",
            type: "success",
          });
          data.groupId = res.data.data;
          this.subGroup(data);
          this.btnLoading = false;
        })
        .catch(() => {
          this.btnLoading = false;
        });
    },
    async subIndexGroup(data) {
      let titleObj = JSON.parse(JSON.stringify(this.titleObj));
      titleObj.cnt = this.getInfo(titleObj);
      data.groupExtInfo = JSON.stringify(titleObj);
      data.groupType = 1;

      // 先修改分组名称
      await this.subUpdateGroup([data]);
      // let questionArr = data.questionList; // 在取出试题  去修改试题
      // questionArr = JSON.parse(JSON.stringify(questionArr));
      // questionArr = questionArr.map((item) => {
      //   let data = {
      //     sheetUuid: item.sheetUuid,
      //     id: item.id,
      //     questionScore: item.questionScore,
      //     questionNum: item.questionNum,
      //     groupKey: item.groupKey,
      //   };
      //   if (item.parentMaterialId) {
      //     data.questionNum = item.questionFNum + `（${item.questionNum}）`;
      //   }
      //   return data;
      // });

      // await this.setupdateQuestionList(questionArr);
      this.editQuestion([data]);
    },
    async setupdateQuestionList(questionList) {
      await updateQuestionList(questionList)
        .then(() => {
          this.$message({
            showClose: true,
            message: "修改成功!",
            type: "success",
          });
          this.btnLoading = false;
        })
        .catch(() => {
          this.btnLoading = false;
        });
    },
    editQuestion(data) {
      let questionList = getStore({
        name: "questionList",
        type: 1,
      });
      questionList = questionList.map((item) => {
        data.map((index) => {
          if (item.groupId == index.groupId) {
            Object.assign(item, index);
            let titleObj = JSON.parse(JSON.stringify(this.titleObj));
            titleObj.cnt = this.getInfo(titleObj);
            item.groupExtInfo = JSON.stringify(titleObj);
          }
        });
        return item;
      });
      this.$parent.setNewSort(questionList);
      this.btnLoading = false;
      this.dialogVisible = false;
    },
    submitFn() {
      this.$refs.indexGroup.validate((valid) => {
        if (valid) {
          this.submit();
        } else {
          return false;
        }
      });
    },
    submit() {
      let questionList = getStore({
        name: "questionList",
        type: 1,
      });

      if (this.indexGroup.groupId) {
        this.subIndexGroup(JSON.parse(JSON.stringify(this.indexGroup)));
      } else {
        this.subSaveGroup(questionList);
        // 添加逻辑
      }
    },
    subGroup(data) {
      let questionList = getStore({
        name: "questionList",
        type: 1,
      });
      data.questionList = [];
      data.isShow = 1;

      questionList.push(data);
      this.$parent.setNewSort(questionList);
      this.dialogVisible = false;
    },
    init(group) {
      this.dialogVisible = true;
      this.$nextTick(() => {
        this.$refs.indexGroup.resetFields();
        if (group) {
          let questionList = getStore({
            name: "questionList",
            type: 1,
          });
          this.indexGroup = questionList.filter(
            (item) => item.groupId == group.groupId
          )[0];
          if (this.indexGroup.groupExtInfo) {
            this.titleObj = JSON.parse(this.indexGroup.groupExtInfo);
            delete this.indexGroup.groupExtInfo;
          } else {
            this.titleObj = Object.assign(this.titleObj, this.$data.titleObj);
          }
        } else {
          this.titleObj = Object.assign(this.titleObj, this.$data.titleObj);
          this.indexGroup = { groupTitle: "" };
        }
      });
    },
  },
};
</script>
<style lang="scss">
// .editQuestionInfo {
.input-box {
  .el-form-item__content {
    display: flex;
    align-items: center;
    .el-button {
      margin-left: 15px;
    }
  }
}

.score-model-box {
  padding: 18px;
  background: #f9fafc;
  max-height: 300px;
  .questionList-title {
    display: inline-block;
    min-width: 40px;
    text-align: right;
  }
  .el-form-item__label {
    width: 0 !important;
  }
  .el-form-item__content {
    margin-left: 0 !important;
  }
  .el-input {
    width: 100px;
    margin-right: 10px;
  }
}
// }
</style>
<style lang="scss" scoped>
.item-box {
  display: flex;
  align-items: center;
  .label {
    width: 52px;
  }
  > div {
    flex-shrink: 0;
  }
  .el-select {
    width: 100px;
    margin-left: 8px;
  }
}
.el-button {
  padding: 8px 16px;
}
.el-form-item {
  margin-bottom: 18px;
  // display: flex;
  // width: 100%;
}
</style>
