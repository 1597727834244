<template>
  <div class="sortList edit-scroll-style">
    <div class="sortList-title">
      目录
      <span>长按可拖动顺序</span>
    </div>
    <div class="add-btn">
      <el-button style="width: 100%" @click="addGroup()">添加大题</el-button>
      <el-button style="width: 100%" @click="addPaper()">添加分卷</el-button>
    </div>
    <div class="question-num-text">
      共 {{ getNums() }} 题
      <template v-if="setting.isScore == 1">
        (总分{{ getScore() }}分)
        <img
          src="@/static/homework/icon_edit.png"
          alt=""
          @click="editGroupAll()"
        />
      </template>
    </div>
    <draggable v-model="questionList" @end="handleMouseUpAll">
      <div
        v-for="(item, index) in questionList"
        :key="index"
        class="question-list"
        :class="{ 'paper-group': item.groupType == 1 }"
        @click="clickFFn(item)"
      >
        <div v-if="item.isShow" class="question-number-title">
          <div>
            <span class="question-number-title-name">{{
              item.groupTitle
            }}</span>
            <span v-if="item.groupType != 1">
              (共{{ getNums(item.groupId) }}题
              <template v-if="setting.isScore == 1">
                ，总分{{ getScore(item.groupId) }}分 </template
              >)</span
            >
          </div>
          <div v-if="item.groupType != 1" class="icon-box">
            <img
              src="@/static/homework/icon_edit.png"
              alt=""
              @click="editGroup(item)"
            />
            <img
              src="@/static/homework/icon_del.png"
              alt=""
              @click="delGroup(item)"
            />
          </div>
        </div>
        <template v-if="item.groupType != 1">
          <draggable
            v-model="item.questionList"
            class="draggable-box"
            :group="{ name: questionList }"
            @end="handleMouseUp"
          >
            <div
              v-for="(qitem, qindex) in item.questionList"
              :id="qitem.questionId"
              :key="qindex"
              class="question-number"
              @click="clickFn(qitem)"
            >
              {{ qitem.questionNum }}
            </div>
          </draggable>
        </template>
      </div>
    </draggable>

    <editQuestionInfo ref="editQuestionInfo"></editQuestionInfo>
    <editQuestionAll ref="editQuestionAll"></editQuestionAll>
  </div>
</template>
<script>
import draggable from "vuedraggable";
import editQuestionInfo from "./editQuestionInfo.vue";
import editQuestionAll from "./editQuestionAll.vue";
import { updateQuestionList, updateGroup } from "@/core/api/homework/sheet";
import { formatToSingleDecimal } from "@/core/util/scoreUtil";
import { mapGetters } from "vuex";
export default {
  name: "SortList",
  components: {
    draggable,
    editQuestionInfo,
    editQuestionAll,
  },

  data() {
    return {
      questionList: [],
      setting: {},
      dragIndex: null,
      overIndex: null,
      materialItem: [],
    };
  },
  computed: {
    ...mapGetters(["GET_HW_QUESTION_LIST"]),
  },
  created() {},
  methods: {
    clickFFn(item) {
      if (item.groupType == 1) {
        let data = {
          questionId: item.groupId,
        };
        this.clickFn(data);
      }
    },
    clickFn(item) {
      // 获取id位置滚动到指定位置
      let id = item.questionId + "-find";
      let el = document.getElementById(id);
      el.scrollIntoView({
        behavior: "smooth",
        inline: "nearest",
        block: "center",
      });
    },
    init() {
      this.setting = this.$parent.setting;
      //过滤出一个复合题的数组  删除  排序的时候需要

      let questionList = JSON.parse(JSON.stringify(this.$parent.questionList));
      let array = [];
      questionList = questionList.map((item) => {
        array = array.concat(
          item.questionList.filter((index) => index.parentMaterialId)
        );
        item.questionList = item.questionList.filter(
          (index) => !index.parentMaterialId
        );
        return item;
      });
      // // 测试代码
      // questionList = questionList.map((item, index) => {
      //   if (index == 2) {
      //     item.groupType = 1;
      //   } else {
      //     item.groupType = 0;
      //   }
      //   return item;
      // });
      this.materialItem = array;
      this.questionList = JSON.parse(JSON.stringify(questionList));
    },
    editGroup(item) {
      this.$refs.editQuestionInfo.init(item);
    },
    addGroup() {
      this.$refs.editQuestionInfo.init();
    },
    addPaper() {
      let questionList = JSON.parse(JSON.stringify(this.questionList));
      if (questionList.length == 1 && questionList[0].isShow == false) {
        this.$message({
          showClose: true,
          message: "请先添加大题!",
          type: "error",
        });
        return;
      }
      this.$parent.addPaper();
    },
    delGroup(item) {
      let questionList = JSON.parse(JSON.stringify(this.questionList));
      if (item.questionList.length > 0 && questionList.length > 1) {
        this.$message({
          showClose: true,
          message: "请先迁移出当前组所有试题再删除！",
          type: "error",
        });
        return;
      }
      const h = this.$createElement;
      this.$msgbox({
        title: "提示",
        message: h("p", null, [
          h("span", null, "当前正在删除 "),
          h("span", { style: "color: red" }, item.groupTitle),
          h("span", null, " 删除后，不可恢复，确定删除？ "),
        ]),
        showCancelButton: true,
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        beforeClose: (action, instance, done) => {
          if (action === "confirm") {
            instance.confirmButtonLoading = true;
            // 目前先本地删除  删除后再

            this.delGroupFn(item, instance, done);
          } else {
            done();
          }
        },
      })
        .then(() => {})
        .catch(() => {});
    },
    async delGroupFn(item, instance, done) {
      let questionList = JSON.parse(JSON.stringify(this.questionList));
      item = JSON.parse(JSON.stringify(item));
      if (questionList.length == 1) {
        item.isShow = 0;
      } else {
        item.default = 1;
      }
      await updateGroup([item])
        .then(() => {})
        .catch(() => {});

      if (questionList.length == 1) {
        questionList = questionList.map((item) => {
          item.isShow = 0;
          return item;
        });
      } else {
        questionList = questionList.filter(
          (index) => index.groupId != item.groupId
        );
      }
      instance.confirmButtonLoading = false;
      done();

      let questionCopy = JSON.parse(JSON.stringify(questionList));
      let questionTypeList = JSON.parse(JSON.stringify(questionList));
      questionCopy.map((item, key) => {
        let itemQuestionList = item.questionList;
        let indexKey = 0;

        itemQuestionList.map((index) => {
          let arr = this.materialItem.filter(
            (i) => i.parentMaterialId == index.materialId
          );
          indexKey = indexKey + 1;
          if (arr.length > 0) {
            questionTypeList[key].questionList.splice(indexKey, 0, ...arr);
          }

          if (index.materialId) {
            indexKey = indexKey + arr.length;
          }
        });
      });
      // console.log(questionTypeList);
      this.$parent.setNewSort(questionTypeList);
    },
    editGroupAll() {
      this.$refs.editQuestionAll.init();
    },
    async handleMouseUpAll() {
      this.handleMouseUp();
    },
    setFn(questionList) {
      let questionTypeList = JSON.parse(JSON.stringify(this.questionList));
      questionTypeList = questionTypeList.map((item, index) => {
        item.groupPriority = index + 1;
      });

      updateGroup(questionTypeList)
        .then(() => {
          this.$parent.setNewSort(questionList);
        })
        .catch(() => {});
    },
    // 排序后提交的数据
    async handleMouseUp(type) {
      let questionCopy = JSON.parse(JSON.stringify(this.questionList));
      let questionTypeList = JSON.parse(JSON.stringify(this.questionList));

      // 排序后过滤下数组 将parentMatrialId存在的单独提取出来
      let questionList = questionCopy.map((item, key) => {
        let itemQuestionList = item.questionList;
        let indexKey = 0;

        itemQuestionList.map((index) => {
          let arr = this.materialItem.filter(
            (i) => i.parentMaterialId == index.materialId
          );
          indexKey = indexKey + 1;
          if (arr.length > 0) {
            questionTypeList[key].questionList.splice(indexKey, 0, ...arr);
          }

          if (index.materialId) {
            indexKey = indexKey + arr.length;
          }
        });

        return item;
      });

      let key = 0;
      let childKey = 0;
      questionList = questionTypeList.map((item) => {
        item.questionList = item.questionList.map((index) => {
          index.questionContent = index.originalQuestion;
          index.groupKey = item.groupKey;
          if (index.materialId) {
            childKey = 0;
          }
          if (!index.parentMaterialId) {
            key++;
            index.questionNum = key;
          }
          if (index.parentMaterialId) {
            childKey++;
            index.questionNum = childKey;
            index.questionFNum = key;
          }
          index.questionPriority = key;
          return index;
        });
        return item;
      });

      // 再将重新更新下数组 获取出parentMaterialId在materialId中的
      let questionArr = questionList.map((item) => item.questionList);
      questionArr = JSON.parse(JSON.stringify(questionArr));
      questionArr = questionArr.flat();
      questionArr = questionArr.map((item) => {
        let data = {
          sheetUuid: item.sheetUuid,
          id: item.id,
          questionPriority: item.questionPriority,
          questionNum: item.questionNum,
          groupKey: item.groupKey,
        };
        if (item.parentMaterialId) {
          data.questionNum = item.questionFNum + `（${item.questionNum}）`;
        }
        return data;
      });
      questionArr = questionArr.filter((item) => item.id);
      await updateQuestionList(questionArr)
        .then(() => {
          if (type != 1) {
            this.$parent.setNewSort(questionList);
          } else {
            this.setFn(questionList);
          }
        })
        .catch(() => {});
    },
    // 其他页面提交的数据
    subList(questionTypeList) {
      this.$parent.setNewSort(questionTypeList);
    },
    getNums(itemGroupId) {
      let indexKey = 0;
      if (!this.questionList || this.questionList.length == 0) return;
      this.questionList.map((item) => {
        if (item.groupId == itemGroupId || !itemGroupId) {
          item.questionList.map(() => {
            indexKey++;
          });
        }
      });
      return indexKey;
    },
    getScore(itemGroupId) {
      let score = 0;
      if (!this.questionList || this.questionList.length == 0) return;
      // console.log(this.GET_HW_QUESTION_LIST);
      this.GET_HW_QUESTION_LIST.map((item) => {
        if (item.groupId == itemGroupId || !itemGroupId) {
          item.questionList.map((index) => {
            if (index.materialId) return;
            score = score + Number(index.questionScore);
          });
        }
      });
      return formatToSingleDecimal(score);
    },
  },
};
</script>
<style lang="scss" scoped>
.sortList {
  border-top: 1px solid #dee0e7;
  padding: 18px;
  // max-height: calc(100vh - 60px);
  height: calc(100vh - 58px);
  position: fixed;
  left: 0;
  top: 58px;
  width: 330px;
  background-color: #ffffff;
  .draggable-box {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    // column-gap: 14px;
    row-gap: 14px;
    width: 100%;

    // flex-wrap: wrap;
  }
  .paper-group.question-list {
    border: none;
    .question-number-title {
      margin-bottom: 0;
    }
    .question-number-title-name {
      word-break: break-all;
    }
  }
  .question-list {
    padding: 10px;
    padding-right: 0;
    border: 1px solid #dee0e7;
    margin-bottom: 18px;
    cursor: pointer;
    // display: flex;
    // flex-wrap: wrap;

    .question-number-title {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      margin-bottom: 14px;
      .icon-box {
        padding-right: 10px;
        img {
          cursor: pointer;
        }
        img + img {
          margin-left: 10px;
        }
      }
    }
    .question-number {
      background: #f9fafc;
      border-radius: 4px;
      border: 1px solid #dee0e7;
      line-height: 40px;
      width: 40px;
      height: 40px;
      text-align: center;
      cursor: pointer;
    }
  }
  .question-num-text {
    color: #0a1119;
    margin: 18px 0;
    img {
      margin-left: 10px;
      cursor: pointer;
      vertical-align: text-bottom;
    }
  }
  .add-btn {
    display: flex;
    justify-content: space-between;
    margin-top: 6px;
    padding-bottom: 8px;
    border-bottom: 1px solid #dee0e7;
  }
  .sortList-title {
    display: flex;
    justify-content: space-between;
    align-content: flex-end;
    font-size: 16px;
    color: #0a1119;
    span {
      font-size: 14px;
      color: #8c9097;
    }
  }
}
</style>
