<template>
  <div class="editQuestionAll">
    <el-dialog
      :title="`修改分值(总分:${getScore()}分)`"
      :visible.sync="dialogVisible"
      :append-to-body="true"
      width="900px"
    >
      <div class="question-edit-box edit-scroll-style">
        <template v-for="(item, index) in questionList">
          <div v-if="!item.groupType" :key="index">
            <div class="question-list">
              {{ item.groupTitle }} (共{{ getNums(item.groupId) }}题，{{
                getScore(item.groupId)
              }}分)
            </div>

            <div style="margin: 18px 0">
              总分
              <el-input
                v-model="item.scoreAll"
                oninput='value = (value.match(/^[0-9]+(\.[5]{0,1})?/g) ?? [""])[0]'
                class="score-input"
                @blur="item.scoreAll = $event.target.value"
              ></el-input
              >分
              <el-button
                type="primary"
                plain
                style="margin-left: 10px"
                @click="averageFn(item)"
              >
                平均分配
              </el-button>
            </div>
            <div class="question-item-box">
              <!--  -->
              <template v-for="(qitem, qindex) in item.questionList">
                <div
                  v-if="!qitem.materialId"
                  :key="qindex"
                  class="question-item"
                >
                  <span class="question-item-title">
                    <template v-if="qitem.parentMaterialId"
                      >{{ qitem.questionFNum }}({{
                        qitem.questionNum
                      }}).</template
                    >
                    <template v-else>{{ qitem.questionNum }}.</template>
                  </span>
                  <el-input
                    v-model="qitem.questionScore"
                    class="score-input"
                    oninput='value = (value.match(/^[0-9]+(\.[5]{0,1})?/g) ?? [""])[0]'
                    @input="setGroupAll()"
                    @blur="qitem.questionScore = $event.target.value"
                  ></el-input
                  >分
                </div>
              </template>
            </div>
          </div>
        </template>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" :btn-loading="btnLoading" @click="submit()">
          确 定
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { getStore } from "@/core/util/store";
import { formatToSingleDecimal } from "@/core/util/scoreUtil";
import { updateQuestionList } from "@/core/api/homework/sheet";
export default {
  name: "EditQuestionAll",
  data() {
    return {
      dialogVisible: false,
      btnLoading: false,
      questionList: [],
      materialItem: [],
    };
  },
  created() {},
  methods: {
    async setupdateQuestionList(questionList) {
      this.btnLoading = true;
      await updateQuestionList(questionList)
        .then(() => {
          this.$message({
            showClose: true,
            message: "修改成功!",
            type: "success",
          });
          this.btnLoading = false;
        })
        .catch(() => {
          this.btnLoading = false;
        });
    },
    async submit() {
      let questionList = JSON.parse(JSON.stringify(this.questionList));
      questionList = questionList.map((item) => {
        delete item.scoreAll;
        if (item.questionList) {
          item.questionList = item.questionList.map((index) => {
            if (index.materialId) {
              let materialItem = item.questionList.filter(
                (i) => i.parentMaterialId == index.materialId
              );
              let score = 0;
              materialItem.map((i) => {
                score = score + Number(i.questionScore);
              });
              index.questionScore = score;
            }
            return index;
          });
        }
        return item;
      });
      let questionArr = questionList.map((item) => item.questionList);
      questionArr = JSON.parse(JSON.stringify(questionArr));
      questionArr = questionArr.flat();
      questionArr = questionArr.map((item) => {
        let data = {
          sheetUuid: item.sheetUuid,
          id: item.id,
          questionScore: item.questionScore,
          questionNum: item.questionNum,
          groupKey: item.groupKey,
        };
        if (item.parentMaterialId) {
          data.questionNum = item.questionFNum + `（${item.questionNum}）`;
        }
        return data;
      });
      await this.setupdateQuestionList(questionArr);

      this.$parent.subList(questionList);
      this.dialogVisible = false;
    },
    averageFn(item) {
      let score = item.scoreAll * 2;
      let num = item.questionList.filter((index) => !index.materialId).length;
      item.questionList = item.questionList.map((item) => {
        if (!item.materialId) {
          let indexScore = parseInt(score / num);
          score = score - indexScore;
          item.questionScore = formatToSingleDecimal(indexScore / 2);
          num--;
        }

        return item;
      });
      // this.indexGroup = JSON.parse(JSON.stringify(this.indexGroup));
    },
    getNums(itemGroupId) {
      let indexKey = 0;
      if (!this.questionList || this.questionList.length == 0) return;
      this.questionList.map((item) => {
        if (item.groupId == itemGroupId || !itemGroupId) {
          item.questionList.map((index) => {
            if (index.materialId) return;
            indexKey++;
          });
        }
      });
      return indexKey;
    },
    getScore(itemGroupId) {
      let score = 0;
      if (!this.questionList || this.questionList.length == 0) return;
      this.questionList.map((item) => {
        if (item.groupId == itemGroupId || !itemGroupId) {
          item.questionList.map((index) => {
            if (index.materialId) return;
            score = score + Number(index.questionScore);
          });
        }
      });
      return formatToSingleDecimal(score);
    },
    setGroupAll() {
      this.questionList = this.questionList.map((item) => {
        item.scoreAll = 0;
        let score = 0;
        item.questionList.map((index) => {
          if (index.materialId) return;
          score = score + Number(index.questionScore);
        });
        item.scoreAll = formatToSingleDecimal(score);
        return item;
      });
      this.questionList = JSON.parse(JSON.stringify(this.questionList));
    },
    init() {
      let questionList = getStore({
        name: "questionList",
        type: 1,
      });
      // console.log(JSON.parse(JSON.stringify(questionList)));
      // 过滤出不需要显示的数组
      this.questionList = JSON.parse(JSON.stringify(questionList));
      this.setGroupAll();
      this.dialogVisible = true;
    },
  },
};
</script>
<style lang="scss" scoped>
.score-input {
  width: 80px;
  margin-right: 10px;
}
.question-list {
  color: #0a1119;
  font-weight: 500;
}
.question-edit-box {
  max-height: calc(100vh - 300px);
  .question-item-box {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-row-gap: 18px;
    grid-column-gap: 18px;
    margin-bottom: 18px;
    // display: flex;
    // flex-wrap: wrap;

    // .question-item {
    //   min-width: 20%;
    //   // margin-right: 18px;
    //   margin-bottom: 18px;
    // }
    .question-item-title {
      display: inline-block;
      min-width: 40px;
      text-align: right;
    }
  }
  .question-list {
  }
}
.editQuestionAll {
}
</style>
