<template>
  <div class="editQuestionInfo">
    <el-dialog
      :title="indexGroup.groupId ? '编辑大题信息' : '添加大题'"
      :visible.sync="dialogVisible"
      :append-to-body="true"
      width="450px"
    >
      <el-form
        ref="indexGroup"
        :model="indexGroup"
        label-width="60px"
        :rules="rules"
        :hide-required-asterisk="true"
      >
        <el-form-item label="标题：" prop="groupTitle">
          <el-input v-model="indexGroup.groupTitle"> </el-input>
        </el-form-item>
        <template v-if="setting.isScore == 1">
          <el-form-item class="input-box" label="总分：">
            <el-input
              v-model="scoreAll"
              oninput='value = (value.match(/^[0-9]+(\.[5]{0,1})?/g) ?? [""])[0]'
              @blur="scoreAll = $event.target.value"
            >
            </el-input>
            <el-button type="primary" plain @click="averageFn()"
              >平均分配</el-button
            >
          </el-form-item>
          <div class="score-model-box edit-scroll-style">
            <template v-for="(item, index) in indexGroup.questionList">
              <el-form-item
                v-if="!item.materialId"
                :key="index"
                :prop="'questionList.' + index + '.questionScore'"
                :rules="{
                  required: true,
                  message: '分数不能为空',
                  trigger: 'blur',
                }"
              >
                <span class="questionList-title">
                  <template v-if="item.parentMaterialId"
                    >{{ item.questionFNum }}({{ item.questionNum }}).</template
                  >
                  <template v-else>{{ item.questionNum }}.</template>
                </span>
                <el-input
                  v-model="item.questionScore"
                  oninput='value = (value.match(/^[0-9]+(\.[5]{0,1})?/g) ?? [""])[0]'
                  @blur="item.questionScore = $event.target.value"
                  @input="getScoreAll()"
                >
                </el-input>
                分
              </el-form-item>
            </template>
          </div>
        </template>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" :loading="btnLoading" @click="submitFn()">
          确 定
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { getStore } from "@/core/util/store";
import { formatToSingleDecimal } from "@/core/util/scoreUtil";
import {
  updateGroup,
  updateQuestionList,
  saveGroup,
} from "@/core/api/homework/sheet";
export default {
  name: "EditQuestionInfo",
  data() {
    return {
      dialogVisible: false,
      btnLoading: false,
      scoreAll: 0,
      indexGroup: {},
      setting: {},

      rules: {
        groupTitle: [
          { required: true, message: "请输入标题", trigger: "blur" },
          {
            min: 1,
            max: 20,
            message: "长度在 1 到 20 个字符",
            trigger: "blur",
          },
        ],
      },
    };
  },
  created() {},
  methods: {
    averageFn() {
      let score = this.scoreAll * 2;
      let num = this.indexGroup.questionList.filter(
        (index) => !index.materialId
      ).length;
      this.indexGroup.questionList = this.indexGroup.questionList.map(
        (item) => {
          if (!item.materialId) {
            let indexScore = parseInt(score / num);
            score = score - indexScore;
            item.questionScore = formatToSingleDecimal(indexScore / 2);
            num--;
          }
          return item;
        }
      );
      this.indexGroup = JSON.parse(JSON.stringify(this.indexGroup));
    },
    getScoreAll() {
      this.scoreAll = 0;
      let scoreAll = 0;
      this.indexGroup.questionList.map((item) => {
        if (item.materialId) return;
        scoreAll = scoreAll + Number(item.questionScore);
      });
      this.scoreAll = formatToSingleDecimal(scoreAll);
    },
    // 修改分组  单个情况isShow改为1修改
    async subUpdateGroup(data, type) {
      this.btnLoading = true;
      await updateGroup(data)
        .then(() => {
          if (type == "add") {
            this.$message({
              showClose: true,
              message: "添加成功!",
              type: "success",
            });
            this.editQuestion(data);
            this.btnLoading = false;
          }
        })
        .catch(() => {
          this.btnLoading = false;
        });
    },
    async subSaveGroup(questionList) {
      this.btnLoading = true;
      let data = {
        sheetUuid: this.$route.query.uuid,
        groupKey: new Date().getTime(),
        groupPriority: questionList.length + 1,
      };

      Object.assign(data, this.indexGroup);
      await saveGroup(data)
        .then((res) => {
          this.$message({
            showClose: true,
            message: "添加成功!",
            type: "success",
          });
          data.groupId = res.data.data;
          this.subGroup(data);
          this.btnLoading = false;
        })
        .catch(() => {
          this.btnLoading = false;
        });
    },
    async subIndexGroup(data) {
      // 先修改分组名称
      await this.subUpdateGroup([data]);
      let questionArr = data.questionList; // 在取出试题  去修改试题
      questionArr = JSON.parse(JSON.stringify(questionArr));
      questionArr = questionArr.map((item) => {
        let data = {
          sheetUuid: item.sheetUuid,
          id: item.id,
          questionScore: item.questionScore,
          questionNum: item.questionNum,
          groupKey: item.groupKey,
        };
        if (item.parentMaterialId) {
          data.questionNum = item.questionFNum + `（${item.questionNum}）`;
        }
        return data;
      });
      // if (questionArr) {
      //   console.log(JSON.parse(JSON.stringify(questionArr)));
      //   console.log(JSON.parse(JSON.stringify(data)));
      //   return;
      // }
      await this.setupdateQuestionList(questionArr);
      this.editQuestion([data]);
    },
    async setupdateQuestionList(questionList) {
      await updateQuestionList(questionList)
        .then(() => {
          this.$message({
            showClose: true,
            message: "修改成功!",
            type: "success",
          });
          this.btnLoading = false;
        })
        .catch(() => {
          this.btnLoading = false;
        });
    },
    editQuestion(data) {
      let questionList = getStore({
        name: "questionList",
        type: 1,
      });
      questionList = questionList.map((item) => {
        data.map((index) => {
          if (item.groupId == index.groupId) {
            Object.assign(item, index);
          }
        });
        return item;
      });
      this.$parent.subList(questionList);
      this.dialogVisible = false;
    },
    submitFn() {
      this.$refs.indexGroup.validate((valid) => {
        if (valid) {
          this.submit();
        } else {
          return false;
        }
      });
    },
    submit() {
      let questionList = getStore({
        name: "questionList",
        type: 1,
      });

      // 如果只有一条数据  并且isShow为0 那么就修改当前分组
      if (questionList.length == 1 && questionList[0].isShow == 0) {
        let data = questionList[0];
        data.groupTitle = this.indexGroup.groupTitle;
        data.isShow = 1;
        this.subUpdateGroup([data], "add");
        return;
      }

      if (this.indexGroup.groupId) {
        this.indexGroup.questionList = this.indexGroup.questionList.map(
          (index) => {
            if (index.materialId) {
              let materialItem = this.indexGroup.questionList.filter(
                (i) => i.parentMaterialId == index.materialId
              );
              let score = 0;
              materialItem.map((i) => {
                score = score + Number(i.questionScore);
              });
              index.questionScore = score;
            }
            return index;
          }
        );
        this.subIndexGroup(JSON.parse(JSON.stringify(this.indexGroup)));
      } else {
        this.subSaveGroup(questionList);
        // 添加逻辑
      }
    },
    subGroup(data) {
      let questionList = getStore({
        name: "questionList",
        type: 1,
      });
      data.questionList = [];
      data.isShow = 1;

      Object.assign(data, this.indexGroup);
      data = questionList.push(data);
      this.$parent.subList(questionList);
      this.dialogVisible = false;
    },
    init(group) {
      this.dialogVisible = true;
      this.$nextTick(() => {
        this.$refs.indexGroup.resetFields();
        if (group) {
          this.setting = this.$parent.setting;
          let questionList = getStore({
            name: "questionList",
            type: 1,
          });
          this.indexGroup = questionList.filter(
            (item) => item.groupId == group.groupId
          )[0];
          // this.indexGroup = JSON.parse(JSON.stringify(group));
          // console.log(JSON.parse(JSON.stringify(group)));
          this.getScoreAll();
        } else {
          this.setting = {};
          this.indexGroup = { groupTitle: "" };
        }
      });
    },
  },
};
</script>
<style lang="scss">
// .editQuestionInfo {
.input-box {
  .el-form-item__content {
    display: flex;
    align-items: center;
    .el-button {
      margin-left: 15px;
    }
  }
}

.score-model-box {
  padding: 18px;
  background: #f9fafc;
  max-height: 300px;
  .questionList-title {
    display: inline-block;
    min-width: 40px;
    text-align: right;
  }
  .el-form-item__label {
    width: 0 !important;
  }
  .el-form-item__content {
    margin-left: 0 !important;
  }
  .el-input {
    width: 100px;
    margin-right: 10px;
  }
}
// }
</style>
<style lang="scss" scoped>
.el-button {
  padding: 8px 16px;
}
.el-form-item {
  margin-bottom: 18px;
  // display: flex;
  // width: 100%;
}
</style>
