<template>
  <div class="questionAnalysis">
    <el-dialog title="答案解析" :visible.sync="dialogVisible" width="808px">
      <div class="answer-box">
        <div
          v-if="questionDetails.knowledgeNames"
          class="analy-item know-item-box"
          style="padding-bottom: 0"
        >
          <div>【知识点】</div>
          <div
            v-for="(item, index) in questionDetails.knowledgeNames"
            :key="index"
            class="know-item"
          >
            {{ item }}
          </div>
        </div>
        <div v-if="questionDetails.answer" class="analy-item">
          <div>【答案】</div>
          <div v-html="questionDetails.answer"></div>
        </div>
        <div v-if="questionDetails.analysis" class="analy-item">
          <div>【解答】</div>
          <div v-html="questionDetails.analysis"></div>
        </div>
        <div v-if="questionDetails.analyse" class="analy-item">
          <div>【分析】</div>
          <div v-html="questionDetails.analyse"></div>
        </div>
        <div v-if="questionDetails.ability" class="analy-item">
          <div>【点评】</div>
          <div v-html="questionDetails.ability"></div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer"> </span>
    </el-dialog>
  </div>
</template>
<script>
import { getAnswer } from "@/core/api/homework/sheet";
export default {
  name: "QuestionAnalysis",
  data() {
    return {
      dialogVisible: false,
      questionDetails: {},
      question: {},
    };
  },
  created() {},
  methods: {
    init(v) {
      this.question = v;

      this.getQuestion();
    },
    getQuestion() {
      let data = {
        id: this.question.id,
        uuid: this.$route.query.uuid,
      };
      getAnswer(data).then((res) => {
        if (res.data.data.knowledgeNames) {
          res.data.data.knowledgeNames =
            res.data.data.knowledgeNames.split("#");
        }
        this.questionDetails = res.data.data;
        this.dialogVisible = true;
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.questionAnalysis {
  .answer-box {
    border: 1px solid #d5d6db;
    .know-item-box {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      > div {
        margin-bottom: 14px;
      }
      .know-item {
        line-height: 1;
        color: #2474ed;
        background: #f3f8ff;
        border-radius: 4px;
        margin-left: 12px;
        padding: 4px;
      }
    }
    .analy-item {
      border-bottom: 1px dashed #d5d6db;
      padding: 14px 10px;
      &:last-child {
        border-bottom: 0;
      }
    }
  }
}
</style>
