import request from "@/core/services/axios";

export function upload(obj) {
  return request({
    url: "/resource/exampaperrelation/my/upload",
    method: "post",
    data: obj,
  });
}

export function geteditionlist(query) {
  return request({
    url: "/resource/resbook/geteditionlist",
    method: "get",
    params: query,
  });
}

export function getbooklist(query) {
  return request({
    url: "/resource/resbook/getbooklist",
    method: "get",
    params: query,
  });
}

export function exampaperplan(query) {
  return request({
    url: "/resource/exampaperplan/exampaperplan",
    method: "get",
    params: query,
  });
}

export function exampaperplanquestionDel(query) {
  return request({
    url: "/resource/exampaperplanquestion/del",
    method: "delete",
    params: query,
  });
}

// 下载资源库
export function downloadJyeooWord(query) {
  return request({
    url: "/exam/examquestion/downloadJyeooWord",
    method: "get",
    params: query,
  });
}

export function getbookchapterbyid(id) {
  return request({
    url: "/resource/resbook/getbookchapterbyid/" + id,
    method: "get",
    // params: query
  });
}

export function answer(id) {
  return request({
    url: "/resource/examquestion/answer/" + id,
    method: "get",
  });
}

export function examquestionrelationDel(query) {
  return request({
    url:
      "/resource/examquestionrelation/my/" +
      query.type +
      "/" +
      query.examQuestionId,
    method: "delete",
    // params: query
  });
}

export function examquestionrelationMy(obj) {
  return request({
    url: "/resource/examquestionrelation/my",
    method: "post",
    data: obj,
  });
}

export function situation(id) {
  return request({
    url: "/resource/examquestion/situation/" + id,
    method: "get",
    // params: query
  });
}

export function getbooklistbylevelandsubject(obj) {
  return request({
    url: "/resource/resbook/getbooklistbylevelandsubject",
    method: "post",
    data: obj,
  });
}

export function exampaperplanquestion(obj) {
  return request({
    url: "/resource/exampaperplanquestion",
    method: "post",
    data: obj,
  });
}

export function examquestionSearch(obj) {
  return request({
    url: "/resource/examquestion/search/page",

    method: "post",
    data: obj,
  });
}

//校本资源-试题
export function getSchoolQuestions(obj) {
  return request({
    url: "/resource/examquestion/school/question/page",
    method: "post",
    data: obj,
  });
}

//来源列表
export function resourceList(obj) {
  return request({
    url: "/resource/ressource/list",
    method: "get",
    params: obj,
  });
}

//获取地区列表
export function listTree(obj) {
  return request({
    url: "/resource/resregion/jyeoo/tree",
    method: "get",
    params: obj,
  });
}

//获取通用的省市区
export function listCommonTree(obj) {
  return request({
    url: "/resource/resregion/common/tree",
    method: "get",
    params: obj,
  });
}
