<template>
  <div class="editTitle">
    <el-dialog title="作业名称" :visible.sync="dialogVisible" width="524px">
      <el-form
        ref="ruleForm"
        :model="ruleForm"
        :rules="rules"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item label="作业名称：" prop="name">
          <el-input
            v-model="ruleForm.name"
            maxlength="50"
            show-word-limit
            class="text-nums"
          ></el-input>
        </el-form-item>
        <div class="item-box">
          <div class="label">格式：</div>
          <el-select v-model="titleObj.textAlign" placeholder="请选择">
            <el-option label="居左" value="left"> </el-option>
            <el-option label="居中" value="center"> </el-option>
            <el-option label="居右" value="right"> </el-option>
          </el-select>
          <el-select v-model="titleObj.verticalAlign" placeholder="请选择">
            <el-option label="顶端对齐" value="1"> </el-option>
            <el-option label="垂直居中" value="2"> </el-option>
            <el-option label="底部对齐" value="3"> </el-option>
          </el-select>
          <el-select v-model="titleObj.fontSize" placeholder="请选择">
            <el-option
              v-for="(item, index) in sizeItem"
              :key="index"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
          <el-select v-model="titleObj.fontWeight" placeholder="请选择">
            <el-option label="加粗" value="900"> </el-option>
            <el-option label="常规" value="500"> </el-option>
          </el-select>
        </div>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submit()"> 确 定 </el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { getStore, setStore } from "@/core/util/store";
export default {
  name: "EditTitle",
  data() {
    return {
      sizeItem: [],
      dialogVisible: false,
      titleObj: {},
      ruleForm: {
        name: "",
      },
      rules: {
        name: [
          { required: true, message: "请输入作业名称", trigger: "blur" },
          {
            min: 1,
            max: 50,
            message: "长度在 1 到 50 个字符",
            trigger: "blur",
          },
        ],
      },
    };
  },
  created() {
    this.sizeItem = [];
    for (let i = 0; i < 10; i++) {
      this.sizeItem.push({
        value: 16 + i * 2,
        label: 16 + i * 2,
      });
    }
  },
  methods: {
    submit() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.subName();
        } else {
          return false;
        }
      });
    },
    subName() {
      this.settingObj.name = this.ruleForm.name;
      setStore({
        name: "settingObj",
        type: 1,
        content: this.settingObj,
      });
      let setting = getStore({
        name: "setting",
        type: 1,
      });
      Object.assign(setting.title, this.titleObj);
      setStore({
        name: "setting",
        type: 1,
        content: setting,
      });
      this.$parent.setNewView();
      this.dialogVisible = false;
    },
    init() {
      this.dialogVisible = true;
      this.$nextTick(() => {
        this.$refs.ruleForm.resetFields();
        let settingObj = getStore({
          name: "settingObj",
          type: 1,
        });
        let setting = getStore({
          name: "setting",
          type: 1,
        });
        this.settingObj = JSON.parse(JSON.stringify(settingObj));
        this.titleObj = setting.title;
        if (!setting.title.textAlign) {
          this.titleObj = {
            fontSize: 16,
            textAlign: "left",
            // 上下对齐方式1 顶部 2居中 3 底部
            verticalAlign: "1",
            fontWeight: "900",
          };
        }
        this.ruleForm.name = this.settingObj.name;
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.editTitle {
  .item-box {
    display: flex;
    align-items: center;
    > div {
      flex-shrink: 0;
    }
    .el-select {
      width: 100px;
      margin-left: 8px;
    }
  }
}
</style>
