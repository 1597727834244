<template>
  <div
    v-loading="loading"
    class="makeSheet"
    element-loading-text="页面加载中,请稍后!"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0)"
  >
    <sheetHeader ref="sheetHeader"></sheetHeader>
    <div class="edit-scroll-style cnt-f-paper-box">
      <div class="cnt-paper-box">
        <div style="min-width: 330px; flex-shrink: 0"></div>
        <div id="paperBox" class="paper-list-box" style="flex-shrink: 0">
          <paperCnt
            v-for="(item, index) in pdfItem"
            :key="index"
            :ref="'paperCnt' + index"
            :paper-cnt="item"
            :page-num="index + 1"
          >
          </paperCnt>
        </div>
        <div style="width: 330px"></div>
      </div>
    </div>
    <sheetFormat ref="sheetFormat"></sheetFormat>
    <sortList ref="sortList"></sortList>
    <div id="initQuestion"></div>
    <editTitle ref="editTitle"></editTitle>
    <editPaper ref="editPaper"></editPaper>
  </div>
</template>
<script>
let preventScroll;
import sheetHeader from "./components/sheetHeader";
import sheetFormat from "./components/sheetFormat";
import editTitle from "./components/editTitle";
import paperCnt from "./components/paperCnt";
import sortList from "./components/sortList.vue";
import editPaper from "./components/editPaper.vue";
import { unitConversion } from "@/core/util/util";
import { getInfo, getContent } from "@/core/api/homework/sheet";
import {
  getQuestionList,
  setQuestionText,
  getViewQuestionList,
  getQuestionSort,
  getDomObj,
} from "./querstionUtils";
import { setting, A4Config } from "./settingJson";
import { mapGetters } from "vuex";
import { toPdf } from "./htmlToPdf";
import { clearStore, getStore, setStore } from "@/core/util/store";
import { headerJsonOne, headerJsonTwo } from "./settingJson";
export default {
  name: "MakeSheet",
  components: {
    sheetHeader,
    sheetFormat,
    paperCnt,
    sortList,
    editPaper,
    editTitle,
  },
  data() {
    return {
      setting: {},
      pdfItem: [],
      loading: false,
    };
  },
  computed: {
    ...mapGetters(["GET_HW_QUESTION_LIST"]),
  },
  watch: {
    loading(value) {
      if (value) {
        this.lockScroll();
      } else {
        this.unlockScroll();
      }
    },
  },
  mounted() {
    this.initView();
    this.initTips();
  },
  methods: {
    addPaper(data) {
      this.$refs.editPaper.init(data);
    },
    lockScroll() {
      // 锁定滚动
      document.body.classList.add("no-scroll");
    },
    unlockScroll() {
      // 解锁滚动
      document.body.classList.remove("no-scroll");
    },
    initTips() {
      if (this.isBrowserZoomed()) {
        this.showTips();
      }
    },
    showTips() {
      this.$alert(
        "检测到当前浏览器存在缩放,请使用ctrl + 鼠标中键恢复至100%体验更佳",
        "温馨提示",
        {
          confirmButtonText: "我知道了",
          callback: () => {},
        }
      );
    },
    isBrowserZoomed() {
      const scale =
        window.devicePixelRatio *
        (window.innerWidth / document.documentElement.clientWidth);
      return Math.abs(scale - 1) > 0.1; // 如果缩放比例与1相差超过0.1，则认为浏览器被缩放了
    },
    editTitle() {
      this.$refs.editTitle.init();
    },
    setJsonView() {
      this.$refs.sheetFormat.init(this.setting);
      this.$refs.sortList.init();
    },

    initView() {
      if (!this.$route.query.loadFile) {
        clearStore({ type: 1 });
        let data = JSON.parse(JSON.stringify(this.$route.query));
        data.loadFile = true;
        this.$router.push({
          query: data,
        });
        // return;
      }
      let questionList = getStore({
        name: "questionList",
        type: 1,
      });
      let pdfItem = getStore({
        name: "pdfItem",
        type: 1,
      });
      let setting = getStore({
        name: "setting",
        type: 1,
      });
      let settingObj = getStore({
        name: "settingObj",
        type: 1,
      });
      if (pdfItem) {
        this.pdfItem = pdfItem;
      }
      if (questionList) {
        this.questionList = questionList;
        this.$store.commit("SET_HW_QUESTION_LIST", this.questionList);
      } else {
        this.getCnt();
      }
      if (settingObj) {
        this.settingObj = settingObj;
      }

      if (setting) {
        this.setting = setting;
        this.setJsonView();
      }
    },
    async questionEdit(questionList) {
      this.questionList = questionList;
      this.setPaperJson();
    },
    setPaperJson() {
      this.pdfItem = toPdf(this.questionList, this.setting);
      setStore({
        name: "pdfItem",
        content: this.pdfItem,
        type: 1,
      });
      setStore({
        name: "questionList",
        content: this.questionList,
        type: 1,
      });
      // console.log(JSON.parse(JSON.stringify(this.questionList)));
      // setTimeout(() => {
      //   console.log(JSON.parse(JSON.stringify(this.questionList)));
      // }, 4000);
      this.$store.commit("SET_HW_QUESTION_LIST", this.questionList);
      setStore({
        name: "setting",
        content: this.setting,
        type: 1,
      });

      this.setJsonView();
    },
    async setNewView() {
      this.setting = getStore({
        name: "setting",
      });
      this.loading = true;
      setTimeout(() => {
        this.loading = false;
      }, 1000);
      let pdfWidth = this.setting.paperInfo.width;
      let width = new unitConversion().mmConversionPx(pdfWidth);

      if (this.setting.studentIdType == 3) {
        this.setting.title.width = width - 150;
        this.setting.title.height = 65;
      }
      if (this.setting.studentIdType == 2) {
        this.setting.title.width = width - 400;
        this.setting.title.height = 88;
      }

      let questionList = getStore({
        name: "questionList",
      });
      this.questionList = await this.loadQuestion(questionList);
      this.setPaperJson();
    },
    async setNewQuestion(questionList) {
      this.loading = true;
      this.questionList = await this.loadQuestion(questionList);
      this.setPaperJson();
      this.setJsonView();
      setTimeout(() => {
        this.loading = false;
      }, 1000);
    },
    async setQuestionList(questionList) {
      this.loading = true;
      questionList = getQuestionSort(questionList, this.setting);
      this.questionList = await this.loadQuestion(questionList);
      this.setPaperJson();
      this.setJsonView();
      setTimeout(() => {
        this.loading = false;
      }, 1000);
    },
    // 排序
    async setNewSort(questionTypeList) {
      this.loading = true;
      let pdfWidth = this.setting.paperInfo.width;
      let width = new unitConversion().mmConversionPx(pdfWidth);
      questionTypeList = getQuestionSort(questionTypeList, this.setting);

      questionTypeList = await Promise.all(
        questionTypeList.map(async (item) => {
          if (item.groupType == 1) {
            let data = getDomObj(item, width);
            Object.assign(item, data);
          }
          item.questionList = await Promise.all(
            item.questionList.map(async (index) => {
              index.questionContentList = JSON.parse(
                JSON.stringify(index.oldItemQuestion)
              );

              index.questionContentList[0] = setQuestionText(
                index,
                this.setting,
                index.questionContentList[0]
              );
              index.questionCntMsg = JSON.parse(
                JSON.stringify(index.questionContentList)
              );

              return index;
            })
          );
          return item;
        })
      );
      // 等待执行完毕 将所有的小题拼接一份出来
      questionTypeList = this.jionQuestion(questionTypeList);
      // 然后计算高度等
      questionTypeList = await Promise.all(
        questionTypeList.map(async (item) => {
          item.questionList = await Promise.all(
            item.questionList.map(async (index) => {
              // 替换节点  添加题号等
              let dataObj = await getQuestionList(
                index,
                width,
                this.setting,
                this.settingObj
              );
              return dataObj;
            })
          );
          item.description = "";
          return item;
        })
      );
      this.questionList = questionTypeList;
      this.setPaperJson();
      this.setJsonView();
      setTimeout(() => {
        this.loading = false;
      }, 1000);
    },
    getSetting() {
      let paperType = "A4";
      let schoolInfo = getStore({ name: "schoolInfo" });
      // 需要二维码的特殊学校
      if (schoolInfo.id == 21736) {
        setting.studentIdType = "2";
      }
      this.setting = Object.assign({}, setting);
      if (paperType == "A4") {
        this.setting.paperInfo = A4Config;
      }

      if (this.setting.headerStyle == 1) {
        let pdfWidth = this.setting.paperInfo.width;
        let width = new unitConversion().mmConversionPx(pdfWidth);
        if (this.setting.studentIdType == 3) {
          headerJsonOne.title.width = width - 150;
          headerJsonOne.title.height = 65;
        }
        if (this.setting.studentIdType == 2) {
          headerJsonOne.title.width = width - 420;
          headerJsonOne.title.height = 88;
        }
        Object.assign(this.setting, headerJsonOne);
      }
      if (this.setting.headerStyle == 2) {
        let pdfWidth = this.setting.paperInfo.width;
        let width = new unitConversion().mmConversionPx(pdfWidth);
        headerJsonTwo.title.width = width - 20;
        Object.assign(this.setting, headerJsonTwo);
      }
    },

    async loadQuestion(questionTypeList) {
      let pdfWidth = this.setting.paperInfo.width;
      let width = new unitConversion().mmConversionPx(pdfWidth);
      questionTypeList = getQuestionSort(questionTypeList, this.setting);
      questionTypeList = await Promise.all(
        questionTypeList.map(async (item) => {
          item.questionList = await Promise.all(
            item.questionList.map(async (index) => {
              if (index.questionContentList.length == 0) {
                index.questionContentList = [
                  index.questionContent || "<div></div>",
                ];
              }
              // 如果存在的话 就读取之前的信息
              if (index.oldItemQuestion) {
                index.questionContentList = JSON.parse(
                  JSON.stringify(index.oldItemQuestion)
                );
              }
              let questionContentList = await getViewQuestionList(
                index,
                this.setting
              );

              //储存原始数据  替换出来的原始节点保存下载  在之后的数据 会修改原始题干
              if (!index.oldItemQuestion) {
                index.oldItemQuestion = JSON.parse(
                  JSON.stringify(questionContentList)
                );
              }
              // 重新赋值
              index.questionContentList = questionContentList;

              // 替换节点  添加题号等
              index.questionContentList[0] = setQuestionText(
                index,
                this.setting,
                index.questionContentList[0]
              );

              return index;
            })
          );
          item.description = "";
          return item;
        })
      );
      // 等待执行完毕 将所有的小题拼接一份出来
      questionTypeList = this.jionQuestion(questionTypeList);
      // 然后计算高度等
      questionTypeList = await Promise.all(
        questionTypeList.map(async (item) => {
          item.questionList = await Promise.all(
            item.questionList.map(async (index) => {
              // 替换节点  添加题号等
              let dataObj = await getQuestionList(
                index,
                width,
                this.setting,
                this.settingObj
              );
              return dataObj;
            })
          );
          item.description = "";
          return item;
        })
      );
      return questionTypeList;
    },
    jionQuestion(questionList) {
      questionList = questionList.map((item) => {
        let hiddenType = false;
        let materialId = 0;
        item.questionList = item.questionList.map((bItem) => {
          if (bItem.materialId) {
            materialId = bItem.materialId;
            let array = item.questionList.filter(
              (cItem) => cItem.parentMaterialId == bItem.materialId
            );
            let questionArr = array.filter(
              (cItem) => cItem.hwQuestionType != 5
            );

            if (
              questionArr.length > 0 ||
              !array[0].isMerge ||
              this.setting.questionModel == 1
            ) {
              hiddenType = false;
              bItem.questionCntMsg = JSON.parse(
                JSON.stringify(bItem.questionContentList)
              );
            } else {
              hiddenType = true;
              let strArr = array.map((item) => item.questionContentList);
              strArr = strArr.flat();
              let questionArr = bItem.questionContentList.concat(strArr);
              bItem.questionCntMsg = [questionArr.join("")];
            }
          } else {
            bItem.questionCntMsg = JSON.parse(
              JSON.stringify(bItem.questionContentList)
            );
          }
          if (bItem.parentMaterialId == materialId) {
            bItem.hiddenType = hiddenType;
          }
          return bItem;
        });
        return item;
      });
      return questionList;
    },
    getCnt() {
      this.loading = true;
      // this.disableScroll();

      getContent({
        uuid: this.$route.query.uuid,
      })
        .then((res) => {
          let Base64 = require("js-base64").Base64;
          let { extInfo } = res.data.data;
          if (extInfo) {
            extInfo = Base64.decode(extInfo);
            extInfo = JSON.parse(extInfo);
            setStore({
              name: "pdfItem",
              content: extInfo.pdfItem,
              type: 1,
            });
            setStore({
              name: "questionList",
              content: extInfo.questionList,
              type: 1,
            });
            this.$store.commit("SET_HW_QUESTION_LIST", extInfo.questionList);
            setStore({
              name: "setting",
              content: extInfo.setting,
              type: 1,
            });
            setStore({
              name: "settingObj",
              content: extInfo.settingObj,
              type: 1,
            });
            this.pdfItem = JSON.parse(JSON.stringify(extInfo.pdfItem));
            if (!extInfo.setting.lineHeight) {
              extInfo.setting.lineHeight = setting.lineHeight;
            }
            if (!extInfo.setting.hiddenGridHeight) {
              extInfo.setting.hiddenGridHeight = setting.hiddenGridHeight;
            }
            this.setting = extInfo.setting;
            this.questionList = extInfo.questionList;

            this.settingObj = extInfo.settingObj;

            this.setJsonView();
            setTimeout(() => {
              this.loading = false;
            }, 200);
          } else {
            this.loadView();
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    disableScroll() {
      const body = document.body;
      body.addEventListener("touchmove", preventScroll, { passive: false });
      body.addEventListener("wheel", preventScroll, { passive: false });
    },
    enableScroll() {
      const body = document.body;
      body.removeEventListener("touchmove", preventScroll);
      body.removeEventListener("wheel", preventScroll);
    },
    getNewFn() {
      this.loading = true;
      // 将试题答题区域高度复制回去
      this.loadView(1);
    },
    initSetting(data, res) {
      // 排除几大科目问题
      if (data.subjectIds.indexOf(data.subjectId) == -1) {
        this.setting.lineHeight = 2;
      }
      // 生物  数学  不显示 问题
      let arr = [2, 7];
      if (arr.indexOf(data.subjectId) != -1) {
        this.setting.hiddenGrid = "0";
      }
      // 特殊的科目 使用横排
      let array = [2, 8, 9, 5];
      if (array.indexOf(data.subjectId) != -1) {
        this.setting.questionModel = "2";
      }
      this.setting.questionTypeRanking = res.questionTypeRanking || 0;
    },
    loadView() {
      getInfo({
        uuid: this.$route.query.uuid,
      })
        .then(async (res) => {
          let questionTypeList = res.data.data.groupList;
          let data = {
            name: res.data.data.name,
            uuid: res.data.data.uuid,
            levelId: res.data.data.levelId,
            subjectId: res.data.data.subjectId,
            subjectIds: [2, 7, 9, 8, 5, 6, 11],
          };
          setStore({
            name: "settingObj",
            content: data,
            type: 1,
          });
          this.getSetting();
          this.initSetting(data, res.data.data);
          this.settingObj = data;
          this.questionList = await this.loadQuestion(questionTypeList);
          setTimeout(() => {
            this.loading = false;
          }, 200);
          this.setPaperJson();
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
};
preventScroll = function (e) {
  e.preventDefault();
  e.stopPropagation();
};
</script>
<style lang="scss">
@import "./sheetFont.scss";
</style>
<style lang="scss" scoped>
#initQuestion {
  position: fixed;
  left: -1000px;
  width: 1000px;
  height: 500px;
}
.makeSheet {
  background-color: #f4f4f5;
  display: flex;
  justify-content: center;
  width: 100%;
  position: relative;
  .cnt-paper-box {
    display: flex;
    justify-content: center;
  }
  // .cnt-c-paper-box {
  //   display: block;
  //   margin: 0 auto;
  // }
  .cnt-f-paper-box {
    height: calc(100vh - 90px);
    width: calc(100%);
    margin-top: 80px;
    &::-webkit-scrollbar {
      width: 10px;
      height: 10px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #dddddd;
    }
  }
}
</style>
