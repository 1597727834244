<template>
  <!-- <div class="sheetFormat-box"> -->
  <div class="sheetFormat">
    <div v-if="hiddenType" class="format-btn" @click="hiddenBox()">
      展开设置
    </div>
    <div
      :class="{ 'hidden-box': hiddenType }"
      class="format-box edit-scroll-style"
    >
      <div class="tilte">
        <div>
          <div class="hidden-btn" @click="hiddenBox()">
            收起<i class="el-icon-arrow-right"></i>
          </div>
          设置
        </div>

        <span @click="showPdf()">教师批阅规范</span>
      </div>
      <div class="setting-box">
        <div class="setting-item setting-item-one">
          <div class="label">纸张类型：</div>
          <el-radio-group v-model="setting.paperType" @change="checkPaper()">
            <el-radio label="1">A4</el-radio>
            <el-radio label="2">A3</el-radio>
            <el-radio label="3">8K</el-radio>
          </el-radio-group>
        </div>
        <!-- <div
        v-if="setting.paperType != 'A4'"
        class="setting-item setting-item-one"
      >
        <div class="label">栏数：</div>
        <el-radio-group v-model="setting.columnNumber" @change="setView()">
          <el-radio :label="2">2</el-radio>
          <el-radio :label="3">3</el-radio>
        </el-radio-group>
      </div> -->
        <div class="setting-item setting-item-one">
          <div class="label">学号类型：</div>
          <el-radio-group
            v-model="setting.studentIdType"
            @change="checkPaper()"
          >
            <el-radio label="3">填写学号</el-radio>
            <el-radio label="2">条形码</el-radio>
          </el-radio-group>
        </div>
        <div
          v-if="setting.studentIdType == 3"
          class="setting-item setting-item-one"
        >
          <div class="label">学号位数：</div>
          <el-select
            v-model="setting.studentId.number"
            placeholder="请选择"
            filterable
            @change="setView()"
          >
            <el-option
              v-for="item in 8"
              :key="item"
              :label="item + '位'"
              :value="item"
            >
            </el-option>
          </el-select>
        </div>
        <div class="setting-item setting-item-one">
          <div class="label">文字字号：</div>
          <el-input-number
            v-model="setting.fontSize"
            :min="12"
            :max="18"
            label="描述文字"
            @change="setView()"
          >
          </el-input-number>
        </div>
        <div class="setting-item setting-item-one">
          <div class="label">文字行距：</div>
          <el-select
            v-model="setting.lineHeight"
            placeholder="请选择"
            filterable
            @change="setView()"
          >
            <el-option
              v-for="item in lineHeightArr"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <div class="setting-item setting-item-one">
          <div class="label">答题区域：</div>
          <el-select
            v-model="setting.questionModel"
            placeholder="请选择"
            filterable
            @change="setView()"
          >
            <el-option label="答题区在小题下" value="1"> </el-option>
            <el-option label="答题区域在复合题尾" value="2"> </el-option>
          </el-select>
        </div>
        <div class="setting-item setting-item-one">
          <div class="label">答题线条：</div>
          <el-select
            v-model="setting.hiddenGrid"
            placeholder="请选择"
            filterable
            @change="setView()"
          >
            <el-option label="显示" value="1"> </el-option>
            <el-option label="不显示" value="0"> </el-option>
          </el-select>
        </div>
        <div
          v-if="setting.hiddenGrid == 1"
          class="setting-item setting-item-one"
        >
          <div class="label">线条间距：</div>
          <el-input-number
            v-model="setting.hiddenGridHeight"
            size="small"
            label="PX"
            :min="20"
            @change="setView()"
          ></el-input-number>
        </div>
        <div class="setting-item setting-item-one">
          <div class="label">分数设置：</div>
          <el-radio v-model="setting.isScore" label="1" @change="checkScore()"
            >有分数</el-radio
          >
          <el-radio v-model="setting.isScore" label="0" @change="checkScore()"
            >无分数</el-radio
          >
        </div>
        <div class="setting-item setting-item-one">
          <div class="label">排序方式：</div>
          <el-radio
            v-model="setting.questionTypeRanking"
            :label="0"
            @change="checkQuestionType()"
            >自由排序</el-radio
          >
          <el-radio
            v-model="setting.questionTypeRanking"
            :label="1"
            @change="checkQuestionType()"
            >题型排序</el-radio
          >
        </div>
        <div v-if="$route.query.password" class="setting-item setting-item-one">
          <div class="label">装订区域：</div>

          <el-select
            v-model="setting.gutter"
            placeholder="请选择"
            filterable
            @change="ckeckBook()"
          >
            <el-option label="预留" :value="1"> </el-option>
            <el-option label="不预留" :value="0"> </el-option>
          </el-select>
        </div>
        <div v-if="setting.gutter == 1" class="setting-item setting-item-one">
          <div class="label">区域宽度：</div>
          <el-input-number
            v-model="setting.gutterWidth"
            size="small"
            label="PX"
            :min="30"
            :max="90"
            @change="ckeckBook()"
          ></el-input-number>
        </div>
        <!-- <div v-if="setting.isScore == 0" class="setting-item setting-item-one">
        <div class="label">批阅方式：</div>
        <el-radio
          v-model="setting.noScoreScoreType"
          label="1"
          @change="setView()"
        >
          手写框
        </el-radio>
        <el-radio
          v-model="setting.noScoreScoreType"
          label="2"
          @change="setView()"
        >
          对错框
        </el-radio>
      </div> -->
        <!-- <div v-else>
        <div
          v-for="(item, index) in setting.questionTypeItem"
          :key="index"
          class="setting-item setting-item-one"
        >
          <div class="label">{{ item.name }}</div>
          <el-radio-group v-model="item.scoreType" @change="setView()">
            <el-radio label="1">手写框</el-radio>
            <el-radio v-if="item.type == 2" label="2">对错框</el-radio>
            <el-radio v-if="item.type == 5" label="3">划分框</el-radio>
          </el-radio-group>
        </div>
      </div> -->

        <!-- <div class="setting-item">
        <div class="label">打印方式：</div>
        <el-radio-group v-model="setting.printType">
          <el-radio label="1">单面</el-radio>
          <el-radio label="2">双面</el-radio>
        </el-radio-group>
      </div> -->
      </div>
    </div>
  </div>
</template>
<script>
import { setStore, getStore } from "@/core/util/store";
import { unitConversion } from "@/core/util/util";
import { updateQuestionTypeRanking } from "@/core/api/homework/sheet";
import { headerJsonOne } from "../settingJson";
import { A4Config } from "../settingJson";
export default {
  name: "SheetFormat",
  data() {
    return {
      A4Config: A4Config,
      questionType: "",
      indexQeustion: {},
      setting: {},
      lineHeightArr: [],
      hiddenType: false,
    };
  },
  created() {
    this.initNumber();
    // console.log(new unitConversion().pxConversionMm(1527.639));
    // console.log(new unitConversion().mmConversionPx(1616));
  },
  methods: {
    hiddenBox() {
      this.hiddenType = !this.hiddenType;
    },
    setWidth() {
      let width = new unitConversion().pxConversionMm(this.setting.gutterWidth);
      // console.log(width);
      let data = JSON.parse(JSON.stringify(this.A4Config));

      if (this.setting.paperType == "1") {
        data.paperWidth = data.paperWidth - width;
        data.width = data.paperWidth - 18;
        this.setting.paperInfo = data;
      }

      if (this.setting.paperType == "2" || this.setting.paperType == "3") {
        data.paperWidth = data.paperWidth - width / 2;
        data.width = data.paperWidth - 18;
        this.setting.paperInfo = data;
      }
    },
    // 取小装订  回滚宽高代码
    ckeckBook() {
      if (this.setting.gutter) {
        //存在装订线  就减小页面宽度 在后续补充
        this.setWidth();
      } else {
        this.setting.paperInfo = A4Config;
      }

      setStore({
        name: "setting",
        content: this.setting,
        type: 1,
      });
      this.$parent.setNewView();
    },
    checkQuestionType() {
      this.$confirm(
        "切换排序方式，作业卡需要重新排版，将恢复初始状态，当前分卷信息，分组信息及已调整的格式信息会丢失，需要重新设置，确认要切换排序方式吗？",
        "温馨提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(() => {
          let data = {
            questionTypeRanking: this.setting.questionTypeRanking,
            sheetUuid: this.$route.query.uuid,
          };
          updateQuestionTypeRanking(data)
            .then(() => {
              this.$parent.loadView();
            })
            .catch(() => {});
        })
        .catch(() => {
          this.setting.questionTypeRanking =
            this.setting.questionTypeRanking == 1 ? 0 : 1;
        });
    },
    initNumber() {
      this.lineHeightArr = [];
      for (let i = 0; i < 20; i++) {
        this.lineHeightArr.push({
          value: 1 + i / 10,
          label: (1 + i / 10 - 0.5).toFixed(1),
        });
      }
    },
    checkPaper() {
      if (this.setting.paperType == "A4") {
        this.setting.columnNumber = 1;
      }
      if (this.setting.paperType != "A4") {
        if (this.setting.columnNumber == 1) {
          this.setting.columnNumber = 2;
        }
      }
      if (this.setting.gutter) {
        //存在装订线  就减小页面宽度 在后续补充
        this.setWidth();
      } else {
        this.setting.paperInfo = A4Config;
      }
      let setting = JSON.parse(JSON.stringify(this.setting));
      if (setting.headerStyle == 1) {
        Object.assign(setting, headerJsonOne);

        if (this.setting.studentIdType == 3) {
          let pdfWidth = setting.paperInfo.width;
          let width = new unitConversion().mmConversionPx(pdfWidth);
          setting.title.width = width - 120;
        } else {
          setting.title.width = 300;
        }
      }

      setStore({
        name: "setting",
        content: setting,
        type: 1,
      });
      // console.log(this.setting);
      this.$parent.setNewView();
    },
    setNewSort(questionTypeList) {
      this.$parent.setNewSort(questionTypeList);
    },
    showPdf() {
      let url =
        "https://static.wtjy.com/resource/document/%E4%BD%9C%E4%B8%9A%E6%89%B9%E9%98%85%E8%A7%84%E8%8C%83.pdf";
      window.open(url);
    },
    init(setting) {
      this.setting = JSON.parse(JSON.stringify(setting));
      this.questionList = getStore({ name: "questionList" });
      // this.$refs.sortList.init();
    },
    handleClick() {},
    setView() {
      // this.setWidth();
      if (this.setting.isScore == 0) {
        this.setting.questionTypeItem = this.setting.questionTypeItem.map(
          (item) => {
            if (item.type == 2 || item.type == 5) {
              item.scoreType = this.setting.noScoreScoreType;
            }
            return item;
          }
        );
      }
      let setting = JSON.parse(JSON.stringify(this.setting));
      if (setting.headerStyle == 1) {
        Object.assign(setting, headerJsonOne);
        // console.log(this.setting.studentIdType);
        if (this.setting.studentIdType == 3) {
          let pdfWidth = setting.paperInfo.width;
          let width = new unitConversion().mmConversionPx(pdfWidth);

          setting.title.width = width - 120;
        } else {
          setting.title.width = 300;
        }
      }
      setting.studentIdType = this.setting.studentIdType;
      setting.fontSize = this.setting.fontSize;
      setting.studentId.number = this.setting.studentId.number;

      setStore({
        name: "setting",
        content: setting,
        type: 1,
      });
      this.setting = setting;
      this.$parent.setNewView();
    },
    checkScore() {
      if (this.setting.isScore == 0) {
        this.setting.questionTypeItem = this.setting.questionTypeItem.map(
          (item) => {
            item.scoreType = this.setting.noScoreScoreType;
            return item;
          }
        );
      }
      if (this.setting.isScore == 1) {
        this.setting.questionTypeItem = this.setting.questionTypeItem.map(
          (item) => {
            if (item.type == 5) {
              item.scoreType = "1";
            }
            return item;
          }
        );
      }
      let setting = JSON.parse(JSON.stringify(this.setting));
      setStore({
        name: "setting",
        content: setting,
        type: 1,
      });
      this.$parent.setNewView(setting);
    },
  },
};
</script>
<style lang="scss" scoped>
.format-box {
  transition: 0.2s linear;
  width: 330px;
  height: calc(100vh - 58px);
}
.hidden-box {
  width: 1px;
}
.sheetFormat {
  position: fixed;
  right: 0;
  top: 58px;
  z-index: 10;
  background-color: #ffffff;
  ::v-deep {
    .el-tabs .el-tabs__item {
      padding-left: 20px;
    }
  }

  .setting-box {
    // border-bottom: 16px solid #f7f7f7;
    padding: 18px;
    padding-top: 0;
    .el-radio {
      margin-bottom: 0;
    }
    .setting-item {
      margin-top: 18px;
      .el-radio-group {
        display: flex;
        // justify-content: space-between;
        .el-radio {
          margin-right: 16px;
        }
      }
      .label {
        margin-bottom: 18px;
        flex-shrink: 0;
      }
    }
    .setting-item-one {
      display: flex;
      align-items: center;
      .el-select {
        width: 180px;
      }
      .el-input-number {
        width: 180px;
      }
      .el-radio {
        margin-bottom: 0;
      }
      .label {
        min-width: 75px;
        // text-align: ;
        flex-shrink: 0;
        margin-bottom: 0;
      }
    }
  }
  .hidden-btn {
    width: 62px;
    height: 28px;
    background: #eff6ff;
    border-radius: 4px;
    color: #2474ed;
    display: inline-block;
    text-align: center;
    line-height: 28px;
    font-size: 14px;
    cursor: pointer;
    user-select: none;
  }
  .format-btn {
    width: 30px;
    // height: 88px;
    background: #2474ed;
    border-radius: 4px;
    color: #ffffff;
    font-size: 14px;
    text-align: center;
    word-break: break-all;
    position: absolute;
    left: -60px;
    top: 6px;
    padding: 8px;
    cursor: pointer;
    user-select: none;
  }
  .tilte {
    font-size: 16px;
    // border-bottom: 1px solid rgba(0, 0, 0, 0.4);
    padding: 12px 18px 0;
    display: flex;
    justify-content: space-between;

    span {
      font-size: 14px;
      color: #2474ed;
      cursor: pointer;
      user-select: none;
    }
  }
}
</style>
