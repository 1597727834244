<template>
  <div class="subFrom">
    <el-dialog
      title="资源属性"
      :modal-append-to-body="false"
      :close-on-click-modal="false"
      :visible.sync="dialogVisible"
      width="514px"
    >
      <el-form
        ref="ruleForm"
        :model="ruleForm"
        :rules="rules"
        :modal-append-to-body="false"
        label-width="55px"
        class="demo-ruleForm"
      >
        <el-form-item label="名称" prop="name">
          <el-input
            v-model="ruleForm.name"
            maxlength="50"
            show-word-limit
            class="text-nums"
            placeholder="请输入名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="年级">
          <el-select
            v-model="ruleForm.resGradeId"
            clearable
            placeholder="请选择年级"
          >
            <el-option
              v-for="(item, index) in gradeOptions"
              :key="index"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="教材">
          <el-cascader
            v-model="ruleForm.bookIds"
            :options="geteditionlistData"
            clearable
            @change="handleChange()"
          ></el-cascader>
        </el-form-item>
        <el-form-item label="章节">
          <el-cascader
            v-model="ruleForm.chapterIds"
            :options="getbookchapterList"
            :props="defaultProps"
            clearable
          ></el-cascader>
        </el-form-item>
        <el-checkbox v-model="ruleForm.isShare" :true-label="1" :false-label="0"
          >共享到校本库</el-checkbox
        >
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" :loading="btnLoading" @click="submit()">
          确 定
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import {
  gradeHighOptions,
  gradeUniversity,
  gradeMiddleOptions,
  primaryOptions,
} from "@/core/util/constdata";
import {
  getbooklistbylevelandsubject,
  getbookchapterbyid,
} from "@/core/api/resource/knowledgePointsPaper";

export default {
  name: "SubFrom",
  data() {
    return {
      btnLoading: false,
      dialogVisible: false,
      ruleForm: {
        name: "",
        resGradeId: "",
        bookId: "",
        chapterId: "",
        isShare: "",
        bookIds: [],
        chapterIds: [],
      },
      gradeOptions: [],
      geteditionlistData: [],
      getbookchapterList: [],
      chapterList: [],
      defaultProps: {
        children: "children",
        label: "name",
        value: "id",
        checkStrictly: true,
      },
      rules: {
        name: [
          { required: true, message: "请输入名称", trigger: "blur" },
          {
            min: 1,
            max: 50,
            message: "长度在 1 到 50 个字符",
            trigger: "blur",
          },
        ],
      },
    };
  },
  created() {},
  methods: {
    submit() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          let data = JSON.parse(JSON.stringify(this.ruleForm));
          if (data.bookIds.length > 0) {
            data.bookId = data.bookIds[data.bookIds.length - 1];
          } else {
            data.bookId = 0;
          }
          if (data.chapterIds.length > 0) {
            data.chapterId = data.chapterIds[data.chapterIds.length - 1];
          } else {
            data.chapterId = 0;
          }
          data.bookIds = data.bookIds.join(",");
          data.chapterIds = data.chapterIds.join(",");
          this.$parent.subData(data, this);
        } else {
          // console.log('error submit!!');
          return false;
        }
      });
    },
    checkBook() {
      this.ruleForm.chapterId = "";
      let data = this.geteditionlistData.filter(
        (item) => item.value == this.ruleForm.bookId
      );
      if (data.length > 0) {
        this.chapterList = data[0].children;
      } else {
        this.chapterList = [];
      }
    },
    // /通过科目、学段查询教材列表列表
    async geteditionlist(data) {
      await getbooklistbylevelandsubject({
        levelId: data.levelId,
        subjectId: data.subjectId,
      }).then((res) => {
        this.geteditionlistData = [];
        let array = [];
        res.data.data.forEach((index) => {
          if (array.length == 0 || array.indexOf(index.editionId) == -1) {
            array.push(index.editionId);
            this.geteditionlistData.push({
              label: index.editionName,
              value: index.editionId,
              children: [],
            });
          }
          if (array.indexOf[index.editionId] != -1) {
            this.geteditionlistData.forEach((item) => {
              if (item.value == index.editionId) {
                item.children.push({
                  label:
                    index.gradeName +
                    index.editionName +
                    index.termName +
                    index.name,
                  value: index.id,
                });
              }
            });
          }
        });
      });
    },
    // 通过id获取章节
    async getbookchapterbyid(editionId) {
      await getbookchapterbyid(editionId).then((res) => {
        this.getbookchapterList = [];
        this.getbookchapterData = res.data.data;
        let arrList = this.getbookchapterData;
        // 递归生成一个新树形节点
        arrList
          .filter((i) => i.rank == 1)
          .forEach((i) => {
            let obj = i;
            obj.children = this.convertToTreeData(arrList, i.number);
            this.getbookchapterList.push(obj);
          });
      });
    },
    handleChange() {
      this.getbookchapterbyid(this.ruleForm.bookIds[1]);
    },
    //递归形成树形结构
    convertToTreeData(data, pid) {
      const result = [];
      let temp = [];
      for (let i = 0; i < data.length; i++) {
        if (data[i].number.slice(0, -1) == pid) {
          const obj = data[i];
          if (!obj.children) {
            obj.children = new Array();
          }
          temp = this.convertToTreeData(data, data[i].number);
          if (temp.length > 0) {
            obj.children = temp;
          } else {
            delete obj.children;
          }
          result.push(obj);
        }
      }
      // console.log(result);
      return result;
    },
    async init(data) {
      this.chapterList = [];
      this.dialogVisible = true;
      await this.geteditionlist(data);
      this.$nextTick(() => {
        this.$refs.ruleForm.resetFields();
        data = JSON.parse(JSON.stringify(data));
        if (data.bookIds) {
          data.bookIds = data.bookIds.split(",").map((item) => Number(item));
        } else {
          data.bookIds = [];
        }
        if (data.chapterIds) {
          data.chapterIds = data.chapterIds
            .split(",")
            .map((item) => Number(item));
        } else {
          data.chapterIds = [];
        }

        Object.assign(this.ruleForm, data);
        if (this.ruleForm.bookIds && this.ruleForm.bookIds.length > 0) {
          this.handleChange();
        }
        if (this.ruleForm.levelId == 1) {
          this.gradeOptions = primaryOptions;
        }
        if (this.ruleForm.levelId == 2) {
          this.gradeOptions = gradeMiddleOptions;
        }
        if (this.ruleForm.levelId == 3) {
          this.gradeOptions = gradeHighOptions;
        }
        if (this.ruleForm.levelId == 4) {
          this.gradeOptions = gradeUniversity;
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.subFrom {
  .el-select,
  .el-cascader {
    width: 100%;
  }
}
</style>
