<template>
  <div
    :id="'paper' + pageNum"
    class="paperCnt"
    :style="getCntStyle()"
    :class="{
      A3: paperCnt.paperType == 2,
      K8: paperCnt.paperType == 3,
    }"
  >
    <div
      v-if="paperCnt.showBookbinding"
      class="bookline"
      :style="getBook()"
      :class="{
        'bookline-left': paperCnt.gutterLocation == 1,
        'bookline-right': paperCnt.gutterLocation == 2,
      }"
    >
      <span>
        装&nbsp;订 &nbsp;区 &nbsp;域 &nbsp;不 &nbsp;要&nbsp; 答 &nbsp;题
      </span>
    </div>
    <div :style="getPaperCnt()" class="paper-cnt-box">
      <div
        class="paper-sheet"
        :style="{
          width: paperCnt.paperMsg.width + 'px',
          height: paperCnt.paperMsg.height + 'px',
        }"
      >
        <div
          v-if="paperCnt.isHeader"
          class="header-cnt"
          :class="{
            'header-cnt-book': paperCnt.showBookbinding,
          }"
          :style="{
            height: paperCnt.headerHeight - 12 + 'px',
          }"
        >
          <div
            class="title"
            :style="getTitleStyle(paperCnt.title)"
            :class="{
              'is-one-title': paperCnt.studentIdType == 2,
            }"
          >
            <div
              :class="{
                'title-center': paperCnt.title.verticalAlign == 2,
                'title-bottom': paperCnt.title.verticalAlign == 3,
              }"
            >
              {{ paperCnt.name }}
              <img
                class="title-img"
                src="@/static/homework/icon-edit.png"
                alt=""
                @click="showEditTitle()"
              />
            </div>
          </div>
          <div class="code-box" :style="getStyle(paperCnt.codeInfo)">
            二维码区域-{{ pageNum }}
          </div>
          <div v-if="paperCnt.studentIdType == 3" class="student-msg">
            <div class="student-msg-item">
              姓名：
              <div class="student-msg-line"></div>
            </div>
            <div class="class-box student-msg-item">
              班级：
              <div class="student-msg-line"></div>
            </div>
          </div>
          <div
            v-if="paperCnt.studentIdType == 3"
            :style="{
              top: paperCnt.studentId.top + 'px',
              left: paperCnt.studentId.left + 'px',
              width: paperCnt.studentId.number * 28 + 'px',
              height: '28px',
            }"
            class="student-id-one"
          >
            <div
              v-for="(item, index) in paperCnt.studentId.number"
              :key="index"
              :style="{ left: 28 * index + 'px' }"
              class="student-id-item"
            ></div>
          </div>
          <div
            v-if="paperCnt.studentIdType == 2"
            class="student-id-tow"
            :style="getStyle(paperCnt.studentBarCode)"
          >
            <img
              src="https://static.wtjy.com/resource/homework/barcode3%401x.png"
              alt=""
            />
          </div>
        </div>
        <template v-for="(item, index) in paperCnt.questionList">
          <div
            v-if="!item.groupType"
            :key="index"
            :style="{
              fontSize: 0,
            }"
          >
            <div
              v-if="!item.isAdd && item.isShow != 0"
              :style="{
                fontSize: paperCnt.fontSize + 'px',
              }"
              class="question-title"
            >
              {{ item.groupTitle }}(共{{ getNums(item.groupId) }}题<template
                v-if="paperCnt.isScore == 1"
                >，{{ getScore(item.groupId) }}分</template
              >)
            </div>
            <div v-if="item.questionItem.length > 0" class="question-cnt">
              <template v-for="(qitem, qindex) in item.questionItem">
                <template v-if="!item.isHidden">
                  <div
                    v-if="!qitem.isExtend && !qitem.hiddenType"
                    :id="qitem.questionId + '-find'"
                    :key="qindex"
                    class="question-item-box"
                    :style="{
                      height: getQHeight(qitem.questionContentList),
                    }"
                    :top="qitem.top"
                  >
                    <!-- :location="JSON.stringify(qitem.scoreLocation)" -->
                    <div v-if="!qitem.parentMaterialId" class="btn-box">
                      <el-button
                        type="text"
                        size="mini"
                        @click="showQuestion(qitem)"
                      >
                        编辑
                      </el-button>
                      <el-button
                        type="text"
                        size="mini"
                        @click="showQuestionAnswer(qitem)"
                      >
                        解析
                      </el-button>
                      <el-button
                        type="text"
                        size="mini"
                        class="del"
                        @click="delQuestion(qitem)"
                      >
                        删除
                      </el-button>
                    </div>
                    <div
                      v-for="(cntItem, cIndex) in qitem.questionContentList"
                      :id="qitem.questionId + '-' + cIndex"
                      :key="cIndex"
                      :style="{
                        width: cntItem.questionDefaultWidth + 'px',
                        height: cntItem.questionDefaultHeight + 'px',
                        'line-height': paperCnt.lineHeight || 1.5,
                        fontSize: paperCnt.fontSize + 'px',
                      }"
                      v-html="cntItem.question"
                    ></div>

                    <template
                      v-if="
                        (qitem.hwQuestionType == 2 ||
                          qitem.hwQuestionType == 4) &&
                        qitem.showScoreCnt
                      "
                    >
                      <template v-if="qitem.scoreType == 1">
                        <div
                          v-if="paperCnt.isScore == 0"
                          class="filling-box gap-filling-box"
                          :style="getLocation(qitem.scoreLocation)"
                        >
                          <div class="filling-box-border">
                            <div class="title-box">对错</div>
                          </div>
                        </div>
                        <div
                          v-if="paperCnt.isScore == 1"
                          class="filling-box gap-filling-box"
                          :style="getLocation(qitem.scoreLocation)"
                        >
                          <div class="filling-box-border">
                            <div class="list-box">
                              <div class="list-box-text">满分</div>
                              <div class="list-box-score">
                                {{ qitem.questionScore }}
                              </div>
                            </div>
                            <div class="list-box">
                              <div class="list-box-text">得分</div>
                              <div class="list-box-score"></div>
                            </div>
                          </div>
                        </div>
                      </template>
                      <template v-if="qitem.scoreType == 2">
                        <div
                          class="gap-filling-box filling-box filling-box-two"
                          :style="{
                            left:
                              qitem.scoreLocation &&
                              qitem.scoreLocation.left + 'px',
                          }"
                        >
                          <div>
                            <img :src="imgSucc" alt="" />
                          </div>
                          <div>
                            <img :src="imgSectional" alt="" />
                          </div>
                          <div><img :src="imgErr" alt="" /></div>
                        </div>
                      </template>
                    </template>
                  </div>
                  <template
                    v-if="
                      qitem.lastMaterialId &&
                      qitem.questionLocation &&
                      qitem.questionLocation.length > 0
                    "
                  >
                    <div
                      v-for="m in qitem.questionLocation"
                      :key="m.scoreId + 'editor-box-c'"
                      :ref="m.scoreId + 'editor-box-c'"
                      :style="{
                        height: m.contentHeight + 'px',
                      }"
                      class="cnt-editor-box cnt-editor-box-child"
                      :top="m.top"
                    >
                      <div
                        v-for="(j, k) in m.questionList"
                        :key="k"
                        class="list-cnt"
                        :style="{
                          width: j.width - 1 + 'px',
                        }"
                        :scoreLocation="JSON.stringify(j)"
                      >
                        <div class="question-num-text">
                          {{ j.questionNum }}
                        </div>
                        <template v-if="m.lineNums && paperCnt.hiddenGrid == 1">
                          <div
                            v-for="(litem, lindex) in m.lineNums"
                            :key="lindex + 'line-grid'"
                            class="line-grid"
                            :style="getlineLocaiton(litem)"
                          ></div>
                        </template>

                        <!-- 判断下  如果存在追加情况  不能拖拽 -->
                        <div
                          v-if="!m.isNext"
                          class="ui-resizable-s"
                          @mousedown.stop="
                            arrowHeightItem(
                              $event,
                              qitem,
                              m.scoreId + 'editor-box-c',
                              m
                            )
                          "
                        ></div>
                      </div>
                      <template v-if="m.showScoreCnt && qitem.scoreType == 1">
                        <template v-for="(j, k) in m.questionList">
                          <template v-if="paperCnt.isScore == 0">
                            <div
                              :key="k + 'f'"
                              :style="getLocation(j.scoreLocation)"
                              class="filling-box"
                              :top="j.top"
                            >
                              <div class="filling-box-border">
                                <div class="title-box">对错</div>
                              </div>
                            </div>
                          </template>
                          <div
                            v-if="paperCnt.isScore == 1"
                            :key="k + 'f'"
                            class="filling-box"
                            :style="getLocation(j.scoreLocation)"
                          >
                            <div class="filling-box-border">
                              <div class="list-box">
                                <div class="list-box-text">满分</div>
                                <div class="list-box-score">
                                  {{ j.questionScore }}
                                </div>
                              </div>
                              <div class="list-box">
                                <div class="list-box-text">得分</div>
                                <div class="list-box-score"></div>
                              </div>
                            </div></div
                        ></template>
                      </template>
                      <div
                        v-if="m.showScoreCnt && qitem.scoreType == 2"
                        :style="{
                          left:
                            qitem.scoreLocation &&
                            qitem.scoreLocation.left + 'px',
                        }"
                        class="filling-box-two"
                        :top="qitem.top"
                      >
                        <div>
                          <img :src="imgSucc" alt="" />
                        </div>
                        <div>
                          <img :src="imgSectional" alt="" />
                        </div>
                        <div><img :src="imgErr" alt="" /></div>
                      </div>
                    </div>
                  </template>
                  <div
                    v-if="qitem.hwQuestionType == 5 && qitem.contentHeight > 0"
                    :key="qindex + 'editor-box'"
                    :ref="qitem.questionId + 'cntBox'"
                    :style="{
                      height: qitem.contentHeight + 'px',
                    }"
                    class="cnt-editor-box"
                  >
                    <div
                      v-if="
                        qitem.showScoreCnt &&
                        qitem.scoreType == 3 &&
                        qitem.hwQuestionType == 5
                      "
                      class="score-box"
                    >
                      <div
                        v-for="m in 20"
                        :key="m"
                        :style="{ width: getTabWidth(qitem) }"
                      >
                        {{ m | setValue(qitem) }}
                      </div>
                    </div>

                    <template v-for="cItem in GET_HW_QUESTION_LIST">
                      <template
                        v-for="(cntItem, cntIndex) in cItem.questionList"
                      >
                        <template v-if="qitem.questionId == cntItem.questionId">
                          <template v-if="qitem.isExtend">
                            <ckeditor
                              :ref="qitem.questionId"
                              :key="cntIndex"
                              v-model="cntItem.contentInfoAdd"
                              type="inline"
                              :config="getConfig(qitem)"
                              :style="{ height: getHeight(qitem) }"
                              :editor-url="editorUrl"
                            />
                          </template>
                          <template v-else>
                            <ckeditor
                              :ref="qitem.questionId"
                              :key="cntIndex"
                              v-model="cntItem.contentInfo"
                              type="inline"
                              :config="getConfig(qitem)"
                              :style="{ height: getHeight(qitem) }"
                              :editor-url="editorUrl"
                            />
                          </template>
                        </template>
                      </template>
                    </template>
                    <template v-if="qitem.lineNums && paperCnt.hiddenGrid == 1">
                      <div
                        v-for="(litem, lindex) in qitem.lineNums"
                        :key="lindex + 'line-grid'"
                        class="line-grid"
                        :style="getlineLocaiton(litem)"
                      ></div>
                    </template>
                    <!-- 判断下  如果存在追加情况  不能拖拽 -->
                    <div
                      v-if="!qitem.isNext"
                      class="ui-resizable-s"
                      @mousedown.stop="
                        arrowHeight($event, qitem, qitem.questionId + 'cntBox')
                      "
                    ></div>
                    <!-- 打分框显示 -->
                    <template v-if="qitem.showScoreCnt && qitem.scoreType == 1">
                      <template v-if="paperCnt.isScore == 0">
                        <div
                          :style="getLocation(qitem.scoreLocation)"
                          class="filling-box"
                          :top="qitem.top"
                        >
                          <div class="filling-box-border">
                            <div class="title-box">对错</div>
                          </div>
                        </div>
                      </template>
                      <div
                        v-if="paperCnt.isScore == 1"
                        class="filling-box"
                        :style="getLocation(qitem.scoreLocation)"
                      >
                        <div class="filling-box-border">
                          <div class="list-box">
                            <div class="list-box-text">满分</div>
                            <div class="list-box-score">
                              {{ qitem.questionScore }}
                            </div>
                          </div>
                          <div class="list-box">
                            <div class="list-box-text">得分</div>
                            <div class="list-box-score"></div>
                          </div>
                        </div>
                      </div>
                    </template>
                    <div
                      v-if="qitem.showScoreCnt && qitem.scoreType == 2"
                      :style="{
                        left:
                          qitem.scoreLocation &&
                          qitem.scoreLocation.left + 'px',
                      }"
                      class="filling-box-two"
                      :top="qitem.top"
                    >
                      <div>
                        <img :src="imgSucc" alt="" />
                      </div>
                      <div>
                        <img :src="imgSectional" alt="" />
                      </div>
                      <div><img :src="imgErr" alt="" /></div>
                    </div>
                  </div>
                </template>
              </template>
            </div>
          </div>
          <div
            v-else
            :id="item.groupId + '-find'"
            :key="index + 'i'"
            class="question-item-box"
            :style="{
              fontSize: 0,
              height: item.height + 'px',
              width: '100%',
            }"
          >
            <div class="btn-box">
              <el-button type="text" size="mini" @click="showGroup(item)">
                编辑
              </el-button>
              <el-button
                type="text"
                size="mini"
                class="del"
                @click="delGroup(item)"
              >
                删除
              </el-button>
            </div>
            <div v-html="item.htmlDom"></div>
          </div>
        </template>
      </div>

      <div
        v-for="(item, index) in paperCnt.locationPoint"
        :key="index"
        class="location-arr"
        :style="getStyle(item)"
      ></div>

      <div
        v-if="paperCnt.isHeader"
        class="job-number"
        style="line-height: 15px"
        :style="{
          left: paperCnt.locationPoint[2].left + 50 + 'px',
          top: paperCnt.locationPoint[2].top + 'px',
        }"
      >
        <span class="replace-span"></span>
      </div>
      <div
        v-if="paperCnt.footer"
        :id="'footerF' + pageNum"
        class="work-sheet-footer"
        :style="getFStyle(paperCnt.footer)"
      >
        <span :id="'footer' + pageNum">
          第{{ pageNum }}页/共{{ $parent.pdfItem.length }}页</span
        >
      </div>
      <!-- <div
        class="line-test"
        style="
          position: absolute;
          border: 1px solid red;
          width: 20px;
          height: 1px;
          top: 887px;
          left: 219px;
        "
      ></div> -->
      <!--   top: calc(838px + 49px); -->
      <editQuestion ref="editQuestion"></editQuestion>
      <questionAnalysis ref="questionAnalysis"></questionAnalysis>
    </div>
  </div>
</template>
<script>
import CKEditor from "ckeditor4-vue";
import editQuestion from "./editQuestion.vue";
import questionAnalysis from "./questionAnalysis.vue";
import { imgSectional, imgSucc, imgErr } from "../settingJson";
import { formatToSingleDecimal } from "@/core/util/scoreUtil";
import { updateQuestionList, getInfo } from "@/core/api/homework/sheet";
import { mapGetters } from "vuex";

export default {
  name: "PaperCnt",
  components: { editQuestion, questionAnalysis, ckeditor: CKEditor.component },
  filters: {
    setValue(num, item) {
      let returnNum = "";
      // 存在0.5分的情况

      if (item.questionScore < 20) {
        returnNum = 19 - num + 1;
        if (returnNum > item.questionScore) {
          returnNum = "";
        }
      } else {
        if (num < 10) {
          returnNum = 10 - num;
          let qNum = item.questionScore.slice(0, 1);
          if (returnNum > qNum || returnNum == 0) {
            // console.log("sadjaihdjaksdhjk");
            returnNum = "";
          }
        }
        if (num > 10) {
          returnNum = 20 - num;
        }
      }

      return returnNum;
    },
  },
  props: {
    paperCnt: {
      type: Object,
      default: () => {},
    },
    pageNum: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      imgSectional: imgSectional,
      imgSucc: imgSucc,
      imgErr: imgErr,
      editorUrl: "./ckeditor/ckeditor.js",
      questionList: [],
      editorConfig: {
        title: false,
        language: "zh-cn",
        allowedContent: true,
        wirisintegration: true,
        mathJaxClass: "equation",
        // ckeditor_wiris
        extraPlugins:
          "lineheight,image,pastefromword,longTextline,shortLineTwo,CustomImageUpload,drawingGrid,drawingImg",
        removePlugins: "exportpdf,showborders,FontSize,Format",
        font_names: "新宋体;" + "KaiTi",
        toolbar: [
          {
            name: "basicstyles",
            items: [
              "basicstyles",
              "Bold",
              "Underline",
              "Italic",
              "Strike",
              "TextColor",
              "BGColor",
              "fontFamily",
              "fontSize",
              "quicktable",
              "shortLineTwo",
              "ShortMatts",
              "Grid",
              "RemoveFormat",
              "CustomImageUpload",
              "drawingGrid",
              "drawingImg",
            ],
          },
          //     "ckeditor_wiris_formulaEditor",
          // "ckeditor_wiris_formulaEditorChemistry",
          {
            name: "insert",
            items: ["SpecialChar"],
          },
          {
            name: "align",
            items: ["JustifyLeft", "JustifyCenter", "JustifyRight"],
          },
          { name: "styles", items: ["Font", "FontSize", "lineheight"] },
        ],
      },
      mouseDownListener: null,
      mouseDown: false,
      resizableElement: null,
    };
  },
  computed: {
    ...mapGetters(["GET_HW_QUESTION_LIST"]),
  },
  watch: {
    paperCnt: {
      deep: true,
      handler() {
        // this.initList();
        // console.log(JSON.parse(JSON.stringify(this.paperCnt)));
        this.$nextTick(() => {
          // console.log("11111");
          setTimeout(() => {
            this.removeEventListeners();
            this.addEventListeners();
          }, 500);
        });
      },
    },
  },
  created() {},
  mounted() {
    this.addEventListeners();
  },

  beforeDestroy() {
    this.removeEventListeners();
  },
  methods: {
    showGroup(item) {
      this.$parent.addPaper(item);
    },
    delGroup(item) {
      this.$confirm(
        `当前正在删除 ${item.groupTitle} ,删除后不可恢复!`,
        "温馨提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(() => {
          let questionList = JSON.parse(
            JSON.stringify(this.GET_HW_QUESTION_LIST)
          );
          questionList = questionList.filter(
            (index) => item.groupId != index.groupId
          );
          questionList = questionList.map((index, key) => {
            index.groupPriority = key + 1;
            return index;
          });
          this.$parent.setNewQuestion(questionList);
          this.$message({
            type: "success",
            message: "删除成功!",
          });
        })
        .catch(() => {});
    },
    // 添加鼠标按下事件监听 如果鼠标按下了  那么就处理当前节点 对节点大小  修改拖动
    addEventListeners() {
      const dom = document.getElementById("paper" + this.pageNum);
      const elements = dom.querySelectorAll(".edit-operation-img");
      elements.forEach((element) => {
        element.addEventListener("mousedown", this.startResize);
      });
      const elementBtn = dom.querySelectorAll(".click-img-btn");
      elementBtn.forEach((element) => {
        element.addEventListener("mousedown", this.startBtn);
      });
    },
    removeEventListeners() {
      const elements = document.querySelectorAll(".edit-operation-img");
      elements.forEach((element) => {
        element.removeEventListener("mousedown", this.mouseDownListener);
      });
      const elementBtn = document.querySelectorAll(".click-img-btn");

      elementBtn.forEach((element) => {
        element.removeEventListener("mousedown", this.startBtn);
      });
      document.removeEventListener("mousemove", this.resize);
      document.removeEventListener("mouseup", this.stopResize);
    },
    startBtn(event) {
      if (event.button === 0) {
        this.ckeckId = event.target.getAttribute("img-id");
        this.ckecktype = event.target.getAttribute("check-type");
        // console.log(this.ckeckId);

        this.checkQuestionCnt();
      }
    },
    checkQuestionCnt() {
      let ids = this.ckeckId.split("_");
      let boxId = "span-" + this.ckeckId;
      let indexQuestion = {};

      this.GET_HW_QUESTION_LIST.map((item) => {
        item.questionList = item.questionList.map((index) => {
          if (index.questionId == ids[1]) {
            indexQuestion = JSON.parse(JSON.stringify(index));
          }
          return index;
        });
        return item;
      });

      let html = indexQuestion.oldItemQuestion[ids[2]];
      const div = document.createElement("div");
      div.innerHTML = html;
      let replaceDom = div.querySelector(`#${boxId}`);
      //删除指定节点
      removeElementsId(div);
      // 将节点添加到指定位置
      // left right center 是将当前节点添加到第二个子节点
      // 获取现有的第二个子节点
      let oneType = ["left", "right", "center"];
      // 插入新创建的段落作为第二个子节点
      if (oneType.indexOf(this.ckecktype) != -1) {
        let firstChild = div.firstChild;
        replaceDom.style.float = "unset";
        replaceDom.style.display = "block";
        replaceDom.style.textAlign = this.ckecktype;
        div.insertBefore(replaceDom, firstChild.nextSibling);
      }
      let twoType = ["left-text", "right-text"];
      if (twoType.indexOf(this.ckecktype) != -1) {
        let firstChild = div.firstChild;
        if (this.ckecktype == "left-text") {
          replaceDom.style.float = "unset";
        }
        if (this.ckecktype == "right-text") {
          replaceDom.style.float = "right";
        }
        replaceDom.style.display = "inline-block";
        replaceDom.style.verticalAlign = "middle";

        firstChild.insertBefore(replaceDom, firstChild.firstChild);
        // console.log(div);
        // console.log(firstChild);
        // return;
      }
      indexQuestion.oldItemQuestion[ids[2]] = div.innerHTML;
      let questionItem = JSON.parse(JSON.stringify(this.GET_HW_QUESTION_LIST));
      questionItem = questionItem.map((item) => {
        item.questionList = item.questionList.map((index) => {
          if (index.questionId == indexQuestion.questionId) {
            index.oldItemQuestion = indexQuestion.oldItemQuestion;
          }
          return index;
        });
        return item;
      });

      this.$parent.setNewQuestion(questionItem);
      function removeElementsId(node) {
        // 如果当前节点有子节点，继续递归处理每个子节点
        if (node.hasChildNodes()) {
          var childNodes = node.childNodes;
          for (var j = 0; j < childNodes.length; j++) {
            if (childNodes[j].id == boxId) {
              node.removeChild(childNodes[j]);
              break;
            }
            if (childNodes[j].nodeType === Node.ELEMENT_NODE) {
              removeElementsId(childNodes[j]);
            }
          }
        }
      }
    },
    startResize(event) {
      if (event.button === 0) {
        // console.log("1111");
        this.editId = event.target.getAttribute("img-id");
        this.editType = event.target.getAttribute("zoom-type");
        this.hwType = event.target.getAttribute("hw-type");
        // 左键
        this.isResizing = true;
        // console.log(this.editId);
        this.resizableElement = document.getElementById(this.editId);
        this.initialWidth = this.resizableElement.offsetWidth;
        this.initialHeight = this.resizableElement.offsetHeight;
        this.initialX = event.clientX;
        this.initialY = event.clientY;

        document.addEventListener("mousemove", this.resize);
        document.addEventListener("mouseup", this.stopResize);
      }
    },
    resize(event) {
      if (this.isResizing) {
        let newWidth = Math.max(
          this.initialWidth + event.clientX - this.initialX,
          20
        ); // 最小宽度50px
        let newHeight = Math.max(
          this.initialHeight + event.clientY - this.initialY,
          20
        ); // 最小高度50px
        if (newHeight > 650) {
          newHeight = 650;
        }
        if (newWidth > 737) {
          newWidth = 737;
        }
        if (this.hwType == 2) {
          if (newWidth > 650) {
            newWidth = 650;
          }
        }
        if (this.editType == "bottom") {
          this.resizableElement.style.height = `${newHeight}px`;
          this.resizableElement.style.width = `${this.initialWidth}px`;
        }
        if (this.editType == "right") {
          this.resizableElement.style.height = `${this.initialHeight}px`;
          this.resizableElement.style.width = `${newWidth}px`;
        }
        if (this.editType == "zoom") {
          this.resizableElement.style.height = `${newHeight}px`;
          this.resizableElement.style.width = `${newWidth}px`;
        }
      }
    },
    // 鼠标抬起
    stopResize() {
      this.isResizing = false;
      // 鼠标抬起后
      document.removeEventListener("mousemove", this.resize);
      document.removeEventListener("mouseup", this.stopResize);
      if (!this.editId) return;
      // 修改下试题  重新加载页面  但是 不提交到后台
      this.subEditQuestion();
    },
    subEditQuestion() {
      //第一位是字符串 ids 第二位是试题id  第三位是在第几个分组  第四位是试题在当前分组的第几张
      let ids = this.editId.split("_").map((item) => Number(item));
      let questionList = this.GET_HW_QUESTION_LIST;
      let indexQuestionObj = {};
      if (!questionList || questionList.length == 0) return;
      questionList.map((item) => {
        item.questionList.map((index) => {
          if (index.questionId == ids[1]) {
            // 获取当前试题
            indexQuestionObj = index;
          }
        });
      });
      // 重新替换当前试题的节点
      // 渲染节点
      let div = document.createElement("div");
      div.innerHTML = indexQuestionObj.oldItemQuestion[ids[2]];
      let element = div.querySelector(`#${this.editId}`);
      let { width, height } = this.resizableElement.style;
      element.style.width = width;
      element.style.height = height;
      indexQuestionObj = JSON.parse(JSON.stringify(indexQuestionObj));
      indexQuestionObj.oldItemQuestion[ids[2]] = div.innerHTML;
      let questionItem = JSON.parse(JSON.stringify(this.GET_HW_QUESTION_LIST));
      questionItem = questionItem.map((item) => {
        item.questionList = item.questionList.map((index) => {
          if (index.questionId == indexQuestionObj.questionId) {
            index.oldItemQuestion = indexQuestionObj.oldItemQuestion;
          }
          return index;
        });
        return item;
      });
      // console.log(questionItem);
      this.$parent.setNewQuestion(questionItem);
    },
    showEditTitle() {
      this.$parent.editTitle();
    },
    getNums(itemGroupId) {
      let indexKey = 0;
      let questionList = this.GET_HW_QUESTION_LIST;
      if (!questionList || questionList.length == 0) return;
      questionList.map((item) => {
        if (item.groupId == itemGroupId || !itemGroupId) {
          item.questionList.map((index) => {
            if (!index.parentMaterialId) {
              indexKey++;
            }
          });
        }
      });
      return indexKey;
    },
    getLocation(item) {
      if (!item) return;
      let width = `width:${item.width}px;`;
      // let top = `top:${item.top}px;`;
      let height = `height:${item.height}px;`;
      // let lineHeight = `line-height:${item.height}px;`;
      let left = `left:${item.left}px;`;
      return width + top + height + left;
    },
    getScore(itemGroupId) {
      let score = 0;
      let questionList = this.GET_HW_QUESTION_LIST;
      if (!questionList || questionList.length == 0) return;
      questionList.map((item) => {
        if (item.groupId == itemGroupId || !itemGroupId) {
          item.questionList.map((index) => {
            if (!index.materialId) {
              score = score + Number(index.questionScore);
            }
          });
        }
      });
      return formatToSingleDecimal(score);
    },
    arrowHeightItem(e, qitem, key, m) {
      let copyItem = JSON.parse(JSON.stringify(qitem));

      let _this = this;
      let y = e.clientY;
      let elH = this.$refs[key][0].offsetHeight;
      let height;
      document.onmousemove = function (e) {
        height = elH - (y - e.clientY);

        _this.$refs[key][0].style.height = height + "px";
      };

      document.onmouseup = function () {
        if (height !== undefined) {
          _this.subSubmitItem(copyItem, height, m);
        }

        document.onmousemove = null;
        document.onmouseup = null;
      };
    },
    subSubmitItem(copyItem, height, m) {
      m = JSON.parse(JSON.stringify(m));

      if (!m.isNext) {
        let { contentAllHeight, contentHeight } = m;
        contentHeight = contentHeight || 0;
        let cntHeight = contentAllHeight - contentHeight;

        m.contentAllHeight = cntHeight + height;
      } else {
        m.contentAllHeight = height;
      }
      if (m.contentAllHeight < 80) {
        m.contentAllHeight = 80;
      }

      let questionList = JSON.parse(JSON.stringify(this.GET_HW_QUESTION_LIST));
      questionList = questionList.map((item) => {
        item.questionList = item.questionList.map((index) => {
          if (index.questionId == copyItem.questionId) {
            index.questionLocation = index.questionLocation.map((i) => {
              if (i.scoreId == m.scoreId) {
                Object.assign(i, m);
              }
              return i;
            });
          }
          return index;
        });
        return item;
      });
      // console.log(questionList);
      this.$parent.questionEdit(questionList);
    },
    arrowHeight(e, qitem, key) {
      let copyItem = JSON.parse(JSON.stringify(qitem));
      // console.log(copyItem);
      let _this = this;
      let y = e.clientY;
      let elH = this.$refs[key][0].offsetHeight;
      let height;
      document.onmousemove = function (e) {
        height = elH - (y - e.clientY);
        _this.$refs[key][0].style.height = height + "px";
      };
      document.onmouseup = function () {
        if (height !== undefined) {
          _this.subSubmit(copyItem, height);
        }
        document.onmousemove = null;
        document.onmouseup = null;
      };
    },
    subSubmit(copyItem, height) {
      // console.log(copyItem.isNext);
      if (!copyItem.isNext) {
        let { contentAllHeight, contentHeight } = copyItem;
        contentHeight = contentHeight || 0;
        let cntHeight = contentAllHeight - contentHeight;
        copyItem.contentAllHeight = cntHeight + height;
      } else {
        copyItem.contentAllHeight = height;
      }
      let questionList = JSON.parse(JSON.stringify(this.GET_HW_QUESTION_LIST));
      questionList = questionList.map((item) => {
        item.questionList = item.questionList.map((index) => {
          if (index.questionId == copyItem.questionId) {
            let { contentInfo, contentInfoAdd } = index;
            copyItem.contentInfo = contentInfo;
            copyItem.contentInfoAdd = contentInfoAdd;
            delete copyItem.questionContentList;
            // console.log(copyItem);
            Object.assign(index, copyItem);
          }
          return index;
        });
        return item;
      });
      // console.log(questionList);

      this.$parent.questionEdit(questionList);
    },

    getConfig(qitem) {
      let editorConfig = Object.assign({}, this.editorConfig);
      setTimeout(() => {
        // 重定义editor空格键
        this.bindHeadEditor(qitem.questionId);
      }, 500);
      return editorConfig;
    },
    getHeight(qitem) {
      let height = qitem.contentHeight - 2;
      // 如果是解答题  划分框
      if (
        qitem.showScoreCnt &&
        qitem.scoreType == 3 &&
        qitem.hwQuestionType == 5
      ) {
        height = height - 22;
      }
      return height + "px";
    },
    getQHeight(questionContentList) {
      let height = 0;
      if (questionContentList && questionContentList.length > 0) {
        questionContentList.map((item) => {
          height = height + item.questionDefaultHeight;
        });
        return height + "px";
      }
    },
    getTabWidth(qitem) {
      let width = (qitem.questionWidth - 2) / 20;
      return width + "px";
    },
    bindHeadEditor(b) {
      this.$nextTick(() => {
        if (!this.$refs[b] || !this.$refs[b][0]) return;
        var g = this.$refs[b][0].instance;
        if (!g) return;
        g.on("key", function (a) {
          if (32 == a.data.keyCode) return g.insertHtml("\x26#12288;"), !1;
        });
      });
    },
    getlineLocaiton(item) {
      // if (!item) return;
      // let width = `width:${item.width}px;`;
      // console.log(item);
      let { hiddenGridHeight } = this.paperCnt;
      if (!hiddenGridHeight) {
        hiddenGridHeight = 36;
      }
      let top = `top:${item * hiddenGridHeight}px;`;
      // let height = `height:${item.height}px;`;
      // let lineHeight = `line-height:${item.height}px;`;
      // let left = `left:${item.left}px;`;
      return top;
    },
    getStyle(item) {
      if (!item) return;
      let width = `width:${item.width}px;`;
      let top = `top:${item.top}px;`;
      let height = `height:${item.height}px;`;
      let lineHeight = `line-height:${item.height}px;`;
      let left = `left:${item.left}px;`;
      return width + top + height + lineHeight + left;
    },
    getPaperCnt() {
      let width = `width:${this.paperCnt.paperMsg.paperWidth}px;`;
      let top = `padding-top:${this.paperCnt.paperMsg.viewTop}px;`;
      let height = `height:${this.paperCnt.paperMsg.paperHeight}px;`;
      let paddingLeft = `padding-left:${this.paperCnt.paperMsg.viewLeft}px;`;
      let left = `left:${this.paperCnt.gutterWidth}px;`;
      let string = width + top + height + paddingLeft;
      if (this.paperCnt.showBookbinding && this.paperCnt.gutterLocation == 1) {
        string = string + left;
      }
      return string;
    },
    getCntStyle() {
      if (!this.paperCnt) return;
      let width = `width:${this.paperCnt.paperMsg.paperWidth}px;`;
      let height = `height:${this.paperCnt.paperMsg.paperHeight}px;`;
      if (this.paperCnt.showBookbinding) {
        width = `width:${
          this.paperCnt.paperMsg.paperWidth + this.paperCnt.gutterWidth
        }px;`;
      }

      return width + height;
    },
    getBook() {
      if (!this.paperCnt) return;
      let width = `width:${this.paperCnt.gutterWidth}px;`;
      let height = `height:${this.paperCnt.paperMsg.paperHeight}px;`;

      return width + height;
    },
    getTitleStyle(item) {
      if (!item) return;
      let width = `width:${item.width}px;`;
      let top = `top:${item.top}px;`;
      let height = `height:${item.height}px;`;
      let lineHeight = `line-height:1.5;`;
      let left = `left:${item.left}px;`;
      let fontSize = `font-size:${item.fontSize}px;`;
      let fontWeight = `font-weight:${item.fontWeight};`;
      let textAlign = `text-align:${item.textAlign};`;
      return (
        width +
        top +
        height +
        lineHeight +
        left +
        fontSize +
        fontWeight +
        textAlign
      );
    },
    getFStyle(item) {
      if (!item) return;
      let width = `width:100%;`;
      let top = `top:${item.top}px;`;
      let height = `height:${item.height}px;`;
      let lineHeight = `line-height:${item.height}px;`;
      let left = `left:0`;
      return width + top + height + lineHeight + left;
    },
    showQuestion(question) {
      // if (question) {
      //   this.$message({
      //     showClose: true,
      //     message: "等待重新开发",
      //     type: "warning",
      //   });
      //   return;
      // }
      let materialId = question.materialId || question.parentMaterialId;
      // 不管是复合题还是复合小题 都将试题提取出来

      if (materialId) {
        let data = {};
        this.GET_HW_QUESTION_LIST.map((item) => {
          let questionList = item.questionList.filter(
            (i) => i.materialId == materialId
          );
          if (questionList.length > 0) {
            data = JSON.parse(JSON.stringify(questionList[0]));

            data.originalQuestion = data.oldItemQuestion.join("");
            // console.log(data.originalQuestion);
            let list = item.questionList.filter(
              (item) => item.parentMaterialId == materialId
            );
            list = list.map((item) => {
              item.answer = item.questionAnswer;
              item.originalQuestion = item.oldItemQuestion.join("");

              return item;
            });
            data.optionCount = data.optionCount || 4;
            data.children = JSON.parse(JSON.stringify(list));
          }
        });

        // console.log(data);
        this.$refs.editQuestion.init(data);
      } else {
        question.originalQuestion = question.oldItemQuestion.join("");
        question.answer = question.questionAnswer;

        this.$refs.editQuestion.init(question);
      }
    },
    setQuestion(question) {
      let questionList = this.GET_HW_QUESTION_LIST;
      questionList = questionList.map((item) => {
        item.questionList = item.questionList.map((index) => {
          if (index.questionId == question.questionId) {
            index.originalQuestion = question.originalQuestion;
          }
          return index;
        });
        return item;
      });
      this.$parent.setNewQuestion(questionList);
    },
    showQuestionAnswer(v) {
      v = JSON.parse(JSON.stringify(v));
      if (!v.id) {
        let arr = [];
        this.GET_HW_QUESTION_LIST.map((item) => {
          item.questionList.map((index) => {
            if (index.parentMaterialId == v.materialId) {
              arr.push(index);
            }
          });
        });
        v.id = arr[0].id;
      }
      // console.log(v);
      this.$refs.questionAnalysis.init(v);
    },
    delQuestion(v) {
      const h = this.$createElement;
      this.$msgbox({
        title: "提示",
        message: h("p", null, [
          h("span", null, "当前正在删除第 "),
          h(
            "span",
            { style: "color: red" },
            v.parentMaterialId ? v.questionFNum : v.questionNum
          ),
          h("span", null, " 题，删除后不可恢复。 "),
        ]),
        showCancelButton: true,
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        beforeClose: (action, instance, done) => {
          if (action === "confirm") {
            instance.confirmButtonLoading = true;
            // 删除材料题
            if (v.materialId || v.parentMaterialId) {
              this.delMaterialFn(v, instance, done);
            } else {
              // 删除普通试题
              this.delQuestionFn(v, instance, done);
            }
          } else {
            done();
          }
        },
      })
        .then(() => {})
        .catch(() => {});
    },
    // 删除材料的时候  需要将小题全部删除
    delMaterialFn(v, instance, done) {
      let delId = v.materialId || v.parentMaterialId;

      let delArr = [];
      let questionList = this.GET_HW_QUESTION_LIST;
      let key = 0;
      let childKey = 0;
      questionList = questionList.map((item) => {
        let arr = item.questionList.filter((i) => i.parentMaterialId == delId);
        item.questionList = item.questionList.filter(
          (i) => i.materialId != delId && i.parentMaterialId != delId
        );

        delArr = delArr.concat(arr);
        item.questionList = item.questionList.map((index) => {
          index.questionContent = index.originalQuestion;
          index.groupKey = item.groupKey;
          if (index.materialId) {
            childKey = 0;
          }
          if (!index.parentMaterialId) {
            key++;
            index.questionNum = key;
          }
          if (index.parentMaterialId) {
            childKey++;
            index.questionNum = childKey;
            index.questionFNum = key;
          }
          return index;
        });

        return item;
      });

      let questionArr = questionList.map((item) => item.questionList);

      questionArr = questionArr.flat();
      questionArr = questionArr.map((item) => {
        let data = {
          sheetUuid: item.sheetUuid,
          id: item.id,
          questionPriority: item.questionPriority,
          questionNum: item.questionNum,
          groupKey: item.groupKey,
        };
        if (item.parentMaterialId) {
          data.questionNum = item.questionFNum + `（${item.questionNum}）`;
        }
        return data;
      });
      delArr = delArr.map((item) => {
        return {
          id: item.id,
          sheetUuid: item.sheetUuid,
          delFlag: 1,
        };
      });
      questionArr = questionArr.concat(delArr);
      if (questionArr.length == 0) {
        this.$message({
          showClose: true,
          message: "不能删除试卷所有试题",
          type: "error",
        });
        instance.confirmButtonLoading = false;
        return;
      }
      updateQuestionList(questionArr)
        .then(() => {
          instance.confirmButtonLoading = false;
          done();
          this.$parent.setNewQuestion(questionList);
        })
        .catch(() => {
          instance.confirmButtonLoading = false;
        });
    },
    delQuestionFn(v, instance, done) {
      let data = {
        id: v.id,
        sheetUuid: v.sheetUuid,
        delFlag: 1,
      };
      let questionList = this.GET_HW_QUESTION_LIST;
      let key = 0;
      let childKey = 0;
      questionList = questionList.map((item) => {
        item.questionList = item.questionList.filter((i) => i.id != data.id);
        item.questionList = item.questionList.map((index) => {
          index.questionContent = index.originalQuestion;
          index.groupKey = item.groupKey;
          if (index.materialId) {
            childKey = 0;
          }
          if (!index.parentMaterialId) {
            key++;
            index.questionNum = key;
          }
          if (index.parentMaterialId) {
            childKey++;
            index.questionNum = childKey;
            index.questionFNum = key;
          }
          return index;
        });
        return item;
      });
      // console.log(JSON.parse(JSON.stringify(questionList)));
      let questionArr = questionList.map((item) => item.questionList);
      questionArr = questionArr.filter((item) => !item.materialId);
      questionArr = questionArr.flat();
      questionArr = questionArr.map((item) => {
        let data = {
          sheetUuid: item.sheetUuid,
          id: item.id,
          questionPriority: item.questionPriority,
          questionNum: item.questionNum,
          groupKey: item.groupKey,
        };
        if (item.parentMaterialId) {
          data.questionNum = item.questionFNum + `（${item.questionNum}）`;
        }
        return data;
      });
      if (questionArr.length == 0) {
        this.$message({
          showClose: true,
          message: "不能删除试卷所有试题",
          type: "error",
        });
        instance.confirmButtonLoading = false;
        return;
      }
      questionArr.push(data);
      updateQuestionList(questionArr)
        .then(() => {
          instance.confirmButtonLoading = false;
          done();
          this.$parent.setNewQuestion(questionList);
        })
        .catch(() => {
          instance.confirmButtonLoading = false;
        });
    },
    subQuestion(data, that) {
      // 这里需要处理下  如果是普通试题 就可以直接拿着数据保存  但是如果是复核题就需要重组下数据 然后添加回去
      // console.log(data);
      data = JSON.parse(JSON.stringify(data));
      let array = [];
      if (data.children) {
        let list = data.children;
        delete data.children;
        array = [data, ...list];
      } else {
        array = [data];
      }
      // 将前端json需要修改的数据保存
      let questionList = JSON.parse(JSON.stringify(this.GET_HW_QUESTION_LIST));
      questionList = questionList.map((item) => {
        item.questionList = item.questionList.map((index) => {
          array.map((i) => {
            if (i.questionId == index.questionId) {
              index.questionContent = i.originalQuestion;
              index.questionAnswer = i.answer;
              index.optionCount = i.optionCount;
            }
          });
          return index;
        });
        return item;
      });
      array = array.map((item) => {
        return {
          questionContent: item.originalQuestion,
          questionId: item.questionId,
          questionAnswer: item.answer,
          materialId: item.materialId || "",
          sheetUuid: this.$route.query.uuid,
          id: item.id,
          optionCount: item.optionCount,
          hwQuestionId: item.hwQuestionId,
        };
      });
      let ids = array.map((item) => item.questionId);
      // 提交需要修改的数据
      updateQuestionList(array)
        .then(async () => {
          this.subQuestionList = JSON.parse(JSON.stringify(questionList));

          that.dialogVisible = false;
          that.btnLoading = false;
          // 然后去请求接口  获取新的题干
          await this.getInfoItem(that, ids);
          this.$parent.setNewQuestion(this.subQuestionList);
        })
        .catch(() => {
          that.btnLoading = false;
        });
    },
    subNewQuestion(subQuestionList) {
      this.$parent.setQuestionList(subQuestionList);
    },
    async getInfoItem(that, ids) {
      await getInfo({
        uuid: this.$route.query.uuid,
      })
        .then(async (res) => {
          let { groupList } = res.data.data;
          let array = groupList.map((item) => item.questionList);
          array = array.flat();
          array = array.filter((item) => ids.indexOf(item.questionId) != -1);
          this.subQuestionList = this.subQuestionList.map((item) => {
            item.questionList = item.questionList.map((bItem) => {
              let indexQuestion = array.filter(
                (index) => index.questionId == bItem.questionId
              )[0];

              if (indexQuestion) {
                bItem.questionContent = indexQuestion.questionContent;
                bItem.questionContentList = indexQuestion.questionContentList;
                delete bItem.oldItemQuestion;
                // console.log(JSON.parse(JSON.stringify(bItem)));
              }

              return bItem;
            });
            return item;
          });
          // this.$parent.setNewQuestion(questionList);
        })
        .catch(() => {
          that.btnLoading = false;
        });
    },
  },
};
</script>
<style lang="scss" scoped></style>
