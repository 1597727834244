let settingJson, width, height;
import { getStore } from "@/core/util/store";
import { unitConversion } from "@/core/util/util";
// 如果需要题干分页的情况  到时候将追加到下一页的题干高度计算出来  如果是直接翻页 没有追加  就让题干的高度这只未0
/**
 *@param {setting.paperType}  打印方式 1 单面 2 双面 如果是单面的话  每个页面的第一栏都要减去顶部信息的高度
 *@param {setting.printType}  打印方式 A3 A4
 *@param {setting.columnNumber}  栏数 A4只有一栏目前 A3有2 3栏
 *@param {setting.paperInfo}  当前渲染内容能是用的高度 宽度
 *@param {isExtend}  当前试题被截断  来自上一页的添加
 *@param {isNext}  当前试题 向下一页添加了
 */

let type5, type2, data, paperIndex, indexPaper, returnArr, questionAll;

export function toPdf(questionList, setting) {
  questionAll = questionList;
  returnArr = [];
  settingJson = setting;
  const { paperInfo, questionTypeItem, questionModel } = settingJson;

  questionTypeItem.map((item) => {
    if (item.type == 2) {
      type2 = item.scoreType;
    }
    if (item.type == 5) {
      type5 = item.scoreType;
    }
  });
  width = new unitConversion().mmConversionPx(paperInfo.width);
  height = new unitConversion().mmConversionPx(paperInfo.height);
  paperIndex = 1;
  indexPaper = getNewPaper(paperIndex);

  questionList.map((item, fKey) => {
    if (item.groupType == 1) {
      if (item.height > indexPaper.residueHeight) {
        returnArr.push(indexPaper);
        paperIndex++;
        indexPaper = JSON.parse(JSON.stringify(getNewPaper(paperIndex)));
        indexPaper.residueHeight = indexPaper.residueHeight - item.height;
        item.isAdd = false;
        item.questionItem = [];
        indexPaper.questionList.push(item);
      } else {
        indexPaper.residueHeight = indexPaper.residueHeight - 12;
        indexPaper.residueHeight = indexPaper.residueHeight - item.height;
        item.isAdd = false;
        item.questionItem = [];
        indexPaper.questionList.push(item);
      }
      if (fKey == questionList.length - 1) {
        returnArr.push(indexPaper);
        return;
      }
      return;
    }
    // console.log("1111111");
    if (indexPaper.residueHeight < 30) {
      returnArr.push(indexPaper);
      paperIndex++;
      indexPaper = JSON.parse(JSON.stringify(getNewPaper(paperIndex)));
      indexPaper.residueHeight = indexPaper.residueHeight - 12;
    }

    if (fKey == questionList.length - 1 && item.questionList.length == 0) {
      returnArr.push(indexPaper);
      return;
    }
    // 添加判断 如果只是显示标题  就不走下边判断

    // 减去大题题号高度 在减去大题到题框的间距 isShow是否显示大题目
    if (item.isShow == 0) {
      // indexPaper.residueHeight = indexPaper.residueHeight;
    } else {
      indexPaper.residueHeight = indexPaper.residueHeight - 12 - 21;
    }

    data = JSON.parse(JSON.stringify(item));
    delete data.questionList;
    data.questionItem = [];
    data.isAdd = false;

    item.questionList.map((index, key) => {
      // console.log(index.lastMaterialId);
      // 判断下  如果是特殊复核题
      if (index.lastMaterialId && index.questionLocation.length > 0) {
        getMaterialQuestion(index, fKey, key, item);
        return;
      }
      // console.log(questionModel);
      // 只需要处理题干
      if (
        index.hwQuestionType == 12 ||
        index.hwQuestionType == 11 ||
        (questionModel == 2 &&
          index.hwQuestionType == 5 &&
          index.parentMaterialId)
      ) {
        getQuestionTwo(index, fKey, key, item);
        return;
      }
      // 题型1选择题 2填空题 3多选题 4判断题 5解答题 11理科复合题 12文科复合题
      let array = [1, 2, 3, 4];
      if (array.includes(index.hwQuestionType)) {
        getQuestionOne(index, fKey, key, item);
        return;
      }
      // 解答题
      if (index.hwQuestionType == 5) {
        getQuestionFive(index, fKey, key, item);
      }
      // if (index.hwQuestionType == 11) {
      //   getQuestionSix(index, fKey, key, item);
      // }
    });
  });

  // 过滤下  最后 一道试题  如果被取消添加了  那么就不创建一个新页面
  let array = returnArr.filter(
    (key, keyIndex) =>
      !(
        key.questionList.length == 1 &&
        key.questionList[0].questionItem.length == 0 &&
        keyIndex == returnArr.length - 1
      )
  );
  // console.log(JSON.parse(JSON.stringify(returnArr)));

  return array;
}
// 判断下  如果是特殊复核题
function getMaterialQuestion(index, fKey, key, item) {
  index.top = indexPaper.height - indexPaper.residueHeight;
  if (index.hwQuestionType == 2 || index.hwQuestionType == 4) {
    index.scoreType = type2;
  }
  if (index.hwQuestionType == 5) {
    index.scoreType = type5;
  }
  let indexObj = JSON.parse(JSON.stringify(index));
  indexObj.questionLocation = [];
  indexObj.showScoreCnt = true;
  indexObj.scoreType = type5;
  indexObj.isExtend = false;
  indexObj.isNext = false;

  // 填空题判断  打分区域已经添加
  let addScore = false;
  let addHeight = 0;
  indexObj.questionContentList = [];
  if (!indexObj.hiddenType) {
    index.questionContentList.map((citem, cIndex) => {
      if (indexPaper.residueHeight > citem.questionDefaultHeight) {
        indexObj.questionContentList.push(citem);

        indexPaper.residueHeight =
          indexPaper.residueHeight - citem.questionDefaultHeight;
        addHeight = addHeight + citem.questionDefaultHeight;
        if (index.hwQuestionType == 1 || index.hwQuestionType == 3) {
          if (cIndex == 0 && !addScore) {
            addScore = true;
            indexObj.showScoreCnt = true;
            setLoacationOne(indexObj);
          }
        }
        if (index.hwQuestionType == 2 || index.hwQuestionType == 4) {
          if (addHeight >= 60 && !addScore) {
            addScore = true;
            indexObj.showScoreCnt = true;
            setLoacationTwo(indexObj);
          }
        }
      } else {
        delete indexObj.questionLocation;
        if (indexObj.questionContentList.length > 0) {
          data.questionItem.push(indexObj);
        }
        indexPaper.questionList.push(data);
        returnArr.push(indexPaper);
        paperIndex++;
        data = JSON.parse(JSON.stringify(item));
        delete data.questionList;
        data.questionItem = [];
        indexPaper = getNewPaper(paperIndex);
        data.isAdd = true;
        indexObj = JSON.parse(JSON.stringify(index));
        addHeight = 0;
        addHeight = addHeight + citem.questionDefaultHeight;
        indexObj.questionContentList = [];
        indexObj.questionLocation = [];
        indexObj.showScoreCnt = false;
        indexObj.scoreType = type5;
        indexObj.isExtend = false;
        indexObj.isNext = false;
        indexObj.questionContentList.push(citem);
        if (index.hwQuestionType == 1 || index.hwQuestionType == 3) {
          if (cIndex == 0 && !addScore) {
            addScore = true;
            indexObj.showScoreCnt = true;
            setLoacationOne(indexObj);
          }
        }
        if (index.hwQuestionType == 2 || index.hwQuestionType == 4) {
          if (addHeight >= 60 && !addScore) {
            addScore = true;
            indexObj.showScoreCnt = true;
            setLoacationTwo(indexObj);
          }
        }
        // indexObj.top = indexPaper.height - indexPaper.residueHeight;
        indexPaper.residueHeight =
          indexPaper.residueHeight - citem.questionDefaultHeight;
      }
    });
  }
  addMinQuestion(index, indexObj, item, fKey, key);
  // 大题最后一道小题  直接添加
  if (key == item.questionList.length - 1) {
    indexPaper.questionList.push(data);
    // console.log(indexPaper.questionList);
    //  12px的下边距
    indexPaper.residueHeight = indexPaper.residueHeight - 12;
  }
  // 最后一道试题  添加进组
  if (key == item.questionList.length - 1 && fKey == questionAll.length - 1) {
    returnArr.push(indexPaper);
  }
}
// 添加小题
function addMinQuestion(index, indexObj, item, fKey, key) {
  index.questionLocation.map((citem, cindex) => {
    // 如果能添加进去就直接添加  不能添加进当前页面 就先添加部分的信息处理 然后 在将剩下的区域添加到下一页面
    let cData = JSON.parse(JSON.stringify(citem));
    cData.showScoreCnt = true;
    cData.scoreType = type5;
    cData.isExtend = false;
    cData.isNext = false;

    // 添加判断  如果小于80 就直接翻页
    if (indexPaper.residueHeight < 78) {
      data.questionItem.push(indexObj);
      indexPaper.questionList.push(data);
      returnArr.push(indexPaper);
      paperIndex++;
      indexPaper = getNewPaper(paperIndex);
      data = JSON.parse(JSON.stringify(item));
      delete data.questionList;
      data.questionItem = [];
      data.isAdd = true;
      indexObj = JSON.parse(JSON.stringify(index));
      indexObj.isExtend = true;
      indexObj.showScoreCnt = true;
      indexObj.scoreType = type5;
      indexObj.questionLocation = [];
      indexObj.top = indexPaper.height - indexPaper.residueHeight;
      cData = JSON.parse(JSON.stringify(citem));
      cData.top = indexPaper.height - indexPaper.residueHeight;
      if (cData.contentAllHeight > indexPaper.residueHeight) {
        cData.contentAllHeight = indexPaper.residueHeight;
      }
      indexPaper.residueHeight =
        indexPaper.residueHeight - cData.contentAllHeight;
      cData.contentHeight = cData.contentAllHeight;
      cData.lineNums = getlineNums(cData);
      cData.showScoreCnt = true;
      cData.scoreType = type5;
      cData.isExtend = false;
      cData.isNext = false;
      cData.questionList = cData.questionList.map((m, n) => {
        m.scoreLocation = setLoacationM(cData, index, n);
        m.width = indexPaper.paperMsg.width / cData.nums;
        return m;
      });
      indexObj.questionLocation.push(cData);
      if (cindex == index.questionLocation.length - 1) {
        data.questionItem.push(indexObj);
      }
      // 大题最后一道小题  直接添加
      // if (key != item.questionList.length - 1) {
      //   // console.log(JSON.parse(JSON.stringify(data)));
      //   // console.log(paperIndex);
      //   indexPaper.questionList.push(data);
      //   //  12px的下边距
      //   indexPaper.residueHeight = indexPaper.residueHeight - 12;
      // }
      // 最后一道试题  添加进组
      if (
        key != item.questionList.length - 1 &&
        fKey != questionAll.length - 1
      ) {
        returnArr.push(indexPaper);
      }
      return;
    }
    if (citem.contentAllHeight < indexPaper.residueHeight) {
      cData.top = indexPaper.height - indexPaper.residueHeight;
      indexPaper.residueHeight =
        indexPaper.residueHeight - citem.contentAllHeight;
      cData.contentHeight = cData.contentAllHeight;
      cData.lineNums = getlineNums(cData);
      cData.questionList = cData.questionList.map((m, n) => {
        m.scoreLocation = setLoacationM(cData, index, n);
        m.width = indexPaper.paperMsg.width / cData.nums;
        return m;
      });
      indexObj.questionLocation.push(cData);
    } else {
      let residualHeight = citem.contentAllHeight - indexPaper.residueHeight;
      cData.top = indexPaper.height - indexPaper.residueHeight;
      cData.contentHeight = indexPaper.residueHeight;
      cData.lineNums = getlineNums(cData);
      cData.showScoreCnt = true;
      cData.isNext = true;
      cData.questionList = cData.questionList.map((m, n) => {
        m.scoreLocation = setLoacationM(cData, index, n);
        m.width = indexPaper.paperMsg.width / cData.nums;
        return m;
      });
      // 判断下  小于三十就在下一页不添加 然后翻页
      if (residualHeight < 30) {
        cData.isNext = false;
        indexObj.questionLocation.push(cData);
        data.questionItem.push(indexObj);

        indexPaper.questionList.push(data);
        returnArr.push(indexPaper);
        paperIndex++;
        indexPaper = getNewPaper(paperIndex);
        data = JSON.parse(JSON.stringify(item));
        data.questionItem = [];
        data.isAdd = true;
        delete data.questionList;
        indexObj = JSON.parse(JSON.stringify(index));
        indexObj.isExtend = true;
        indexObj.showScoreCnt = false;
        indexObj.scoreType = type5;
        indexObj.questionLocation = [];

        return;
      }
      indexObj.questionLocation.push(cData);
      data.questionItem.push(indexObj);

      indexPaper.questionList.push(data);
      returnArr.push(indexPaper);
      paperIndex++;
      indexPaper = getNewPaper(paperIndex);
      data = JSON.parse(JSON.stringify(item));
      data.questionItem = [];
      data.isAdd = true;
      delete data.questionList;
      indexObj = JSON.parse(JSON.stringify(index));
      indexObj.isExtend = true;
      indexObj.showScoreCnt = false;
      indexObj.scoreType = type5;
      indexObj.questionLocation = [];
      indexObj.top = indexPaper.height - indexPaper.residueHeight;

      cData = JSON.parse(JSON.stringify(citem));
      cData.top = indexPaper.height - indexPaper.residueHeight;
      if (residualHeight > indexPaper.residueHeight) {
        cData.contentHeight = indexPaper.residueHeight;
        cData.lineNums = getlineNums(cData);
      } else {
        cData.contentHeight = residualHeight;
        cData.lineNums = getlineNums(cData);
      }
      indexPaper.residueHeight = indexPaper.residueHeight - cData.contentHeight;
      cData.showScoreCnt = false;
      cData.scoreType = type5;
      cData.isExtend = false;
      cData.isNext = false;
      cData.questionList = cData.questionList.map((m) => {
        m.scoreLocation = null;
        m.width = indexPaper.paperMsg.width / cData.nums;
        return m;
      });
      indexObj.questionLocation.push(cData);
    }

    if (cindex == index.questionLocation.length - 1) {
      data.questionItem.push(indexObj);
    }
  });
}
// 只需要处理题干情况的方法
function getQuestionTwo(index, fKey, key, item) {
  let indexObj = JSON.parse(JSON.stringify(index));
  indexObj.isExtend = false;
  indexObj.showScoreCnt = false;
  indexObj.questionLocation = [];
  indexObj.questionContentList = [];
  if (!indexObj.hiddenType) {
    index.questionContentList.map((cItem) => {
      // console.log(indexPaper.residueHeight > cItem.questionDefaultHeight);
      if (indexPaper.residueHeight > cItem.questionDefaultHeight) {
        indexObj.top = indexPaper.height - indexPaper.residueHeight;
        indexPaper.residueHeight =
          indexPaper.residueHeight - cItem.questionDefaultHeight;
        indexObj.questionContentList.push(cItem);
      } else {
        // 如果翻页了  并且在这个页面添加了 那就将这个高度设置为0
        if (indexObj.questionContentList.length > 0) {
          indexObj.contentHeight = 0;
          data.questionItem.push(indexObj);
        }
        indexPaper.questionList.push(data);
        returnArr.push(indexPaper);
        paperIndex++;
        data = JSON.parse(JSON.stringify(item));
        delete data.questionList;
        data.questionItem = [];
        indexObj = JSON.parse(JSON.stringify(index));
        indexObj.isExtend = false;
        indexObj.showScoreCnt = false;
        indexObj.questionContentList = [];
        indexPaper = getNewPaper(paperIndex);
        indexObj.questionContentList.push(cItem);
        indexObj.top = indexPaper.height - indexPaper.residueHeight;
        indexPaper.residueHeight =
          indexPaper.residueHeight - cItem.questionDefaultHeight;
        data.isAdd = true;
      }
    });
  }
  data.questionItem.push(indexObj);
  // 大题最后一道小题  直接添加
  if (key == item.questionList.length - 1) {
    indexPaper.questionList.push(data);
    //  12px的下边距
    indexPaper.residueHeight = indexPaper.residueHeight - 12;
  }
  // 最后一道试题  添加进组
  if (key == item.questionList.length - 1 && fKey == questionAll.length - 1) {
    returnArr.push(indexPaper);
  }
}
/**
 *
 * @param {*} index  当前试题
 * @param {*} fKey 大题的位置
 * @param {*} key 小题的位置
 * @param {*} item  当前大题
 * @returns
 */
// 选择题解析方式 填空题
function getQuestionOne(index, fKey, key, item) {
  if (index.hwQuestionType == 2 || index.hwQuestionType == 4) {
    index.scoreType = type2;
  }
  if (index.hwQuestionType == 5) {
    index.scoreType = type5;
  }
  index.top = indexPaper.height - indexPaper.residueHeight;
  let indexObj = JSON.parse(JSON.stringify(index));
  delete indexObj.scoreLocation;
  indexObj.questionContentList = [];
  // 填空题判断  打分区域已经添加
  let addScore = false;
  let addHeight = 0;
  index.questionContentList.map((cItem, cIndex) => {
    if (indexPaper.residueHeight > cItem.questionDefaultHeight) {
      indexPaper.residueHeight =
        indexPaper.residueHeight - cItem.questionDefaultHeight;
      addHeight = addHeight + cItem.questionDefaultHeight;
      indexObj.questionContentList.push(cItem);

      if (index.hwQuestionType == 1 || index.hwQuestionType == 3) {
        if (cIndex == 0 && !addScore) {
          addScore = true;
          indexObj.showScoreCnt = true;
          setLoacationOne(indexObj);
        }
      }
      if (index.hwQuestionType == 2 || index.hwQuestionType == 4) {
        if (addHeight >= 60 && !addScore) {
          addScore = true;
          indexObj.showScoreCnt = true;
          setLoacationTwo(indexObj);
        }
      }
      if (index.hwQuestionType == 11 || index.hwQuestionType == 12) {
        delete indexObj.scoreLocation;
      }
      if (cIndex == index.questionContentList.length - 1) {
        data.questionItem.push(indexObj);
      }
    } else {
      // 如果翻页了  并且在这个页面添加了 那就将这个高度设置为0
      if (indexObj.questionContentList.length > 0) {
        // indexObj.showScoreCnt = false;
        data.questionItem.push(indexObj);
      }
      indexPaper.questionList.push(data);
      returnArr.push(indexPaper);
      addHeight = 0;
      paperIndex++;
      data = JSON.parse(JSON.stringify(item));
      addHeight = addHeight + cItem.questionDefaultHeight;
      delete data.questionList;
      data.questionItem = [];
      indexObj = JSON.parse(JSON.stringify(index));
      indexObj.showScoreCnt = false;
      delete indexObj.scoreLocation;
      indexPaper = getNewPaper(paperIndex);
      indexObj.top = indexPaper.height - indexPaper.residueHeight;
      indexObj.questionContentList = [];
      indexObj.questionContentList.push(cItem);
      if (index.hwQuestionType == 1 || index.hwQuestionType == 3) {
        if (cIndex == 0 && !addScore) {
          addScore = true;
          indexObj.showScoreCnt = true;
          setLoacationOne(indexObj);
        }
      }
      if (index.hwQuestionType == 2 || index.hwQuestionType == 4) {
        if (addHeight >= 60 && !addScore) {
          addScore = true;
          indexObj.showScoreCnt = true;
          setLoacationTwo(indexObj);
        }
      }
      if (index.hwQuestionType == 11 || index.hwQuestionType == 12) {
        delete indexObj.scoreLocation;
      }
      indexPaper.residueHeight =
        indexPaper.residueHeight - cItem.questionDefaultHeight;
      data.isAdd = true;
      if (cIndex == index.questionContentList.length - 1) {
        data.questionItem.push(indexObj);
      }
    }
  });

  // 大题最后一道小题  直接添加
  if (key == item.questionList.length - 1) {
    indexPaper.questionList.push(data);
    //  12px的下边距
    indexPaper.residueHeight = indexPaper.residueHeight - 12;
  }
  // 最后一道试题  添加进组
  if (key == item.questionList.length - 1 && fKey == questionAll.length - 1) {
    returnArr.push(indexPaper);
  }
}
/**
 *
 * @param {*} index  当前试题
 * @param {*} fKey 大题的位置
 * @param {*} key 小题的位置
 * @param {*} item  当前大题
 * @returns
 */
function getQuestionFive(index, fKey, key, item) {
  // 是否显示打分框
  index.showScoreCnt = true;
  index.scoreType = type5;
  index.isExtend = false;
  index.isNext = false;
  index.top = indexPaper.height - indexPaper.residueHeight;
  if (!index.contentAllHeight || index.contentAllHeight < 90) {
    index.contentAllHeight = 90;
  }
  let indexObj = JSON.parse(JSON.stringify(index));
  indexObj.questionContentList = [];
  // if (indexObj.questionId == 14113) {
  //   console.log(indexPaper.residueHeight);
  // }

  index.questionContentList.map((cItem) => {
    if (indexPaper.residueHeight > cItem.questionDefaultHeight) {
      indexPaper.residueHeight =
        indexPaper.residueHeight - cItem.questionDefaultHeight;
      indexObj.questionContentList.push(cItem);
    } else {
      // 如果翻页了  并且在这个页面添加了 那就将这个高度设置为0
      if (indexObj.questionContentList.length > 0) {
        indexObj.contentHeight = 0;
        index.showScoreCnt = false;
        data.questionItem.push(indexObj);
      }
      indexPaper.questionList.push(data);
      returnArr.push(indexPaper);
      paperIndex++;
      data = JSON.parse(JSON.stringify(item));
      delete data.questionList;
      data.questionItem = [];
      indexObj = JSON.parse(JSON.stringify(index));
      indexObj.showScoreCnt = true;
      indexObj.questionContentList = [];
      indexObj.questionContentList.push(cItem);
      indexPaper = getNewPaper(paperIndex);
      indexObj.top = indexPaper.height - indexPaper.residueHeight;
      indexPaper.residueHeight =
        indexPaper.residueHeight - cItem.questionDefaultHeight;
      data.isAdd = true;
    }
  });

  subQuestionAdd(indexObj, fKey, key, item);
  // 如果试题高度足够  就直接添加
  // setLoacationFive(indexObj);
}
// 解答题题干添加
function subQuestionAdd(index, fKey, key, item) {
  if (indexPaper.residueHeight < 78) {
    addPaper(index);
    paperIndex++;
    indexPaper = getNewPaper(paperIndex);
    index = JSON.parse(JSON.stringify(index));
    data = JSON.parse(JSON.stringify(item));
    delete data.questionList;
    data.questionItem = [];
    extendPaper(index, data, indexPaper);
    if (key == item.questionList.length - 1) {
      indexPaper.questionList.push(data);
      //  12px的下边距
      indexPaper.residueHeight = indexPaper.residueHeight - 12;
    }
    if (key == item.questionList.length - 1 && fKey == questionAll.length - 1) {
      returnArr.push(indexPaper);
    }
    return;
  }
  // 如果能添加进去就直接添加  不能添加进当前页面 就先添加部分的信息处理 然后 在将剩下的区域添加到下一页面
  if (index.contentAllHeight > indexPaper.residueHeight) {
    // 计算下添加后还剩的高度
    let questionResidueHeight =
      index.contentAllHeight - indexPaper.residueHeight;
    index.contentHeight = indexPaper.residueHeight;
    index.lineNums = getlineNums(index);
    if (questionResidueHeight > 30) {
      index.isNext = true;
    }
    index.showScoreCnt = true;
    //  后台解析定位信息的位置
    setLoacationFive(index, indexPaper);
    indexPaper.residueHeight = 0;
    data.questionItem.push(index);
    indexPaper.questionList.push(data);
    returnArr.push(indexPaper);
    paperIndex++;
    indexPaper = getNewPaper(paperIndex);
    // 拷贝一下当前试题 向下一页添加
    index = JSON.parse(JSON.stringify(index));
    if (indexPaper.residueHeight < questionResidueHeight) {
      questionResidueHeight = indexPaper.residueHeight;
    }
    index.contentHeight = questionResidueHeight;
    index.lineNums = getlineNums(index);
    index.isExtend = true;
    index.isNext = false;
    index.showScoreCnt = false;
    delete index.scoreLocation;
    // 拷贝一下当前大题 向下一页添加
    data = JSON.parse(JSON.stringify(item));
    delete data.questionList;
    data.questionItem = [];
    data.isAdd = true;

    // 小于三十 就不添加了
    if (questionResidueHeight > 30) {
      index.top = indexPaper.height - indexPaper.residueHeight;
      indexPaper.residueHeight =
        indexPaper.residueHeight - questionResidueHeight;
      setLoacationFive(index, indexPaper);
      if (index.showScoreCnt == false) {
        delete index.scoreLocation;
      }
      data.questionItem.push(index);
    }
  } else {
    // index.top = indexPaper.height - indexPaper.residueHeight;
    indexPaper.residueHeight =
      indexPaper.residueHeight - index.contentAllHeight;
    index.contentHeight = index.contentAllHeight;
    index.lineNums = getlineNums(index);
    //  后台解析定位信息的位置
    setLoacationFive(index, indexPaper);
    data.questionItem.push(index);
  }
  if (key == item.questionList.length - 1) {
    indexPaper.questionList.push(data);
    //  12px的下边距
    indexPaper.residueHeight = indexPaper.residueHeight - 12;
  }
  if (key == item.questionList.length - 1 && fKey == questionAll.length - 1) {
    returnArr.push(indexPaper);
  }
}

// 页面不足情况  添加进去
function addPaper(index) {
  index.isNext = true;
  index.showScoreCnt = false;
  delete index.scoreLocation;
  index.contentHeight = 0;
  data.questionItem.push(index);
  indexPaper.questionList.push(data);
  returnArr.push(indexPaper);
}
// 翻页后对剩余信息处理
function extendPaper(index) {
  index.isExtend = true;
  index.isNext = false;
  index.showScoreCnt = true;
  index.top = indexPaper.height - indexPaper.residueHeight;
  // 后台解析定位信息的位置

  data.isAdd = true;
  data.questionItem = [];
  if (indexPaper.residueHeight < index.contentAllHeight) {
    index.contentHeight = indexPaper.residueHeight;
    index.lineNums = getlineNums(index);
    indexPaper.residueHeight = 0;
  } else {
    indexPaper.residueHeight =
      indexPaper.residueHeight - index.contentAllHeight;
    index.contentHeight = index.contentAllHeight;
    index.lineNums = getlineNums(index);
  }
  setLoacationFive(index);
  data.questionItem.push(index);
}
function getlineNums(index) {
  let { contentHeight } = index;
  let { hiddenGridHeight } = settingJson;
  if (!hiddenGridHeight) {
    hiddenGridHeight = 36;
  }
  // 留一点安全距离
  let number = (contentHeight - 5) / hiddenGridHeight;
  return Math.floor(number);
}
// 选择题
function setLoacationOne(index) {
  index.scoreLocation = {
    top: index.top,
    left: 0,
    width: 75,
    height: 25,
  };
  let questionWidth, questionDefaultHeight;
  if (index.questionContentList) {
    questionWidth = index.questionContentList[0].questionDefaultWidth;
    questionDefaultHeight = index.questionContentList[0].questionDefaultHeight;
  }
  index.scoreLocation.areaWidth = index.scoreLocation.width;
  index.scoreLocation.areaLeft = index.scoreLocation.left - 6 - 6;
  index.scoreLocation.areaTop = index.scoreLocation.top - 6.5;
  index.scoreLocation.areaHeight = index.scoreLocation.height + 6.5;
  index.answerLocation = {
    top: index.top,
    left: 0,
    width: questionWidth,
    height: questionDefaultHeight,
  };
}
// 填空题
function setLoacationTwo(index) {
  // console.log(index);
  let questionWidth,
    questionDefaultHeight = 0;
  if (index.questionContentList) {
    questionWidth = index.questionContentList[0].questionDefaultWidth;
    questionDefaultHeight = index.questionContentList[0].questionDefaultHeight;
  }

  if (index.scoreType == 1) {
    index.scoreLocation = {
      top: index.top,
      left: questionWidth,
      width: indexPaper.isScore == 0 ? 56 : 92,
      height: 51,
    };

    index.scoreLocation.areaWidth = index.scoreLocation.width - 9 - 35 + 6;
    index.scoreLocation.areaLeft = index.scoreLocation.left + 9 + 35 - 3;
    index.scoreLocation.areaTop = index.scoreLocation.top + 21 - 3;
    index.scoreLocation.areaHeight = index.scoreLocation.height - 21 + 6;
    // 无分数模式
    if (indexPaper.isScore == 0) {
      index.scoreLocation.left = questionWidth + 14;
      index.scoreLocation.areaWidth = index.scoreLocation.width - 9 + 6;
      index.scoreLocation.areaLeft = index.scoreLocation.left + 9 - 3;
      index.scoreLocation.areaTop = index.scoreLocation.top + 22 - 3;
      index.scoreLocation.areaHeight = index.scoreLocation.height - 22 + 6;
    }
  }
  if (index.scoreType == 2) {
    index.scoreLocation = {
      top: index.top,
      left: questionWidth + 5,
      width: 70,
      height: 24,
    };
  }
  index.answerLocation = {
    top: index.top,
    left: 0,
    width: questionWidth,
    height: questionDefaultHeight,
  };
}
// 解答题的定位信息
function setLoacationFive(index) {
  let { isExtend, questionContentList } = index;
  let questionDefaultHeight = 0;
  questionContentList.map((qitem) => {
    questionDefaultHeight = questionDefaultHeight + qitem.questionDefaultHeight;
  });
  if (index.scoreType == 1) {
    index.scoreLocation = {
      top: index.top + 4 + Number(isExtend ? 0 : questionDefaultHeight),
      left: index.questionWidth - (indexPaper.isScore == 0 ? 58 : 94),
      width: indexPaper.isScore == 0 ? 56 : 92,
      height: 51,
    };
    index.scoreLocation.areaWidth = index.scoreLocation.width - 4 - 35 + 4;
    index.scoreLocation.areaLeft = index.scoreLocation.left + 4 + 35 - 3;
    index.scoreLocation.areaTop = index.scoreLocation.top + 21 + 1 - 3;
    index.scoreLocation.areaHeight = index.scoreLocation.height - 21 - 1 + 6;
    if (indexPaper.isScore == 0) {
      index.scoreLocation.areaWidth = index.scoreLocation.width - 9 + 4;
      index.scoreLocation.areaLeft = index.scoreLocation.left + 4 - 3;
      index.scoreLocation.areaTop = index.scoreLocation.top + 21 - 3;
      index.scoreLocation.areaHeight = index.scoreLocation.height - 21 + 6;
    }
  }
  if (index.scoreType == 2) {
    index.scoreLocation = {
      top: index.top + 4 + Number(isExtend ? 0 : questionDefaultHeight),
      left: index.questionWidth - 86,
      width: 70,
      height: 24,
    };
  }
  if (index.scoreType == 3) {
    index.scoreLocation = {
      top: index.top + Number(isExtend ? 0 : questionDefaultHeight),
      left: 0,
      width: index.questionWidth,
      height: 22,
    };
  }
  index.answerLocation = {
    top: index.top + Number(isExtend ? 0 : questionDefaultHeight),
    left: 0,
    width: index.questionWidth,
    height: index.contentHeight,
  };
}
// 复合题定位信息
function setLoacationM(index, citem, n) {
  // console.log(n);
  // let { isExtend, questionDefaultHeight } = citem;
  let scoreLocation = {};
  if (index.scoreType == 1) {
    scoreLocation = {
      top: index.top,
      left:
        citem.questionWidth / index.nums +
        (citem.questionWidth / index.nums) * n -
        (indexPaper.isScore == 0 ? 56 : 94) -
        2,
      width: indexPaper.isScore == 0 ? 56 : 92,
      height: 51,
    };
    scoreLocation.areaWidth = scoreLocation.width - 4 - 35 + 4;
    scoreLocation.areaLeft = scoreLocation.left + 4 + 35 - 3;
    scoreLocation.areaTop = scoreLocation.top + 21 + 4 - 3;
    scoreLocation.areaHeight = scoreLocation.height - 21 - 4 + 6;
    if (indexPaper.isScore == 0) {
      scoreLocation.areaWidth = scoreLocation.width - 9 + 4;
      scoreLocation.areaLeft = scoreLocation.left + 4 - 3;
      scoreLocation.areaTop = scoreLocation.top + 21 - 3;
      scoreLocation.areaHeight = scoreLocation.height - 21 + 6;
    }
  }
  if (index.scoreType == 2) {
    scoreLocation = {
      top: index.top,
      left:
        citem.questionWidth / index.nums +
        (citem.questionWidth / index.nums) * n -
        (indexPaper.isScore == 0 ? 74 : 94) -
        2,
      width: 70,
      height: 24,
    };
  }
  if (index.scoreType == 3) {
    scoreLocation = {
      top: index.top,
      left: 0,
      width: index.questionWidth,
      height: 22,
    };
  }
  return scoreLocation;
}
// 创建一个新页
function getNewPaper(index) {
  const {
    printType,
    paperType,
    paperInfo,
    headerStyle,
    columnNumber,
    // studentId,
    // studentIdType,
    // studentBarCode,
    gutter,
    gutterWidth,
  } = settingJson;

  let indexPaper = {
    residueHeight: height,
    height: height,
    questionList: [],
    paperMsg: {
      width: width,
      height: height,
      viewLeft: new unitConversion().mmConversionPx(9),
      viewTop: new unitConversion().mmConversionPx(13),
      paperWidth: new unitConversion().mmConversionPx(paperInfo.paperWidth),
      paperHeight: new unitConversion().mmConversionPx(paperInfo.paperHeight),
    },
    fontSize: settingJson.fontSize,
    paperIndex: index,
    showBookbinding: false,
    gutterLocation: null,
  };

  if (paperType == "A4" || paperType == 1) {
    if (printType == 1) {
      indexPaper.isHeader = true;
    }
    if (printType == 2) {
      indexPaper.isHeader = index % 2 == 1 ? true : false;
    }
    // A4纸的正反面都有装订线
    if (gutter == 1) {
      settingJson = JSON.parse(JSON.stringify(settingJson));
      if (index == 1) {
        settingJson.studentBarCode.left =
          settingJson.studentBarCode.left - gutterWidth;
      }
      indexPaper.showBookbinding = true;
      if (indexPaper.isHeader) {
        indexPaper.gutterLocation = "1";
      } else {
        indexPaper.gutterLocation = "2";
      }
    }

    if (indexPaper.isHeader) {
      indexPaper.headerHeight = headerStyle == 1 ? 77 : 160;
      indexPaper.residueHeight = indexPaper.height - indexPaper.headerHeight;
      indexPaper.top = indexPaper.headerHeight;
    } else {
      indexPaper.headerHeight = 0;
      indexPaper.top = 0;
      indexPaper.residueHeight = indexPaper.height;
    }
    indexPaper.footer = {
      left: 364,
      top: indexPaper.paperMsg.paperHeight - indexPaper.paperMsg.viewTop + 15,
      width: new unitConversion().mmConversionPx(paperInfo.paperWidth),
      height: 15,
    };
    indexPaper.locationPoint = [
      {
        left: indexPaper.paperMsg.viewLeft,
        top: 40 - 15,
        width: 38,
        height: 15,
      },
      {
        left: indexPaper.paperMsg.paperWidth - indexPaper.paperMsg.viewLeft * 2,
        top: 40 - 15,
        width: 38,
        height: 15,
      },
      {
        left: indexPaper.paperMsg.viewLeft,
        top: indexPaper.paperMsg.paperHeight - indexPaper.paperMsg.viewTop + 15,
        width: 38,
        height: 15,
      },
      {
        left: indexPaper.paperMsg.paperWidth - indexPaper.paperMsg.viewLeft * 2,
        top: indexPaper.paperMsg.paperHeight - indexPaper.paperMsg.viewTop + 15,
        width: 38,
        height: 15,
      },
    ];
    indexPaper.pdfLocationPoint = [
      { left: indexPaper.paperMsg.viewLeft, top: 40, width: 38, height: 15 },
      {
        left: indexPaper.paperMsg.paperWidth - indexPaper.paperMsg.viewLeft * 2,
        top: 40,
        width: 38,
        height: 15,
      },
      {
        left: indexPaper.paperMsg.viewLeft,
        top: indexPaper.paperMsg.paperHeight - indexPaper.paperMsg.viewTop + 15,
        width: 38,
        height: 15,
      },
      {
        left: indexPaper.paperMsg.paperWidth - indexPaper.paperMsg.viewLeft * 2,
        top: indexPaper.paperMsg.paperHeight - indexPaper.paperMsg.viewTop + 15,
        width: 38,
        height: 15,
      },
    ];
  }
  if (paperType == "A3" || paperType == 2 || paperType == 3) {
    if (printType == 1) {
      if (columnNumber == 2) {
        indexPaper.isHeader = index % 2 == 1 ? true : false;
      }
      if (columnNumber == 3) {
        indexPaper.isHeader = index % 3 == 1 ? true : false;
      }
    }
    if (printType == 2) {
      if (columnNumber == 2) {
        indexPaper.isHeader = index % 4 == 1 ? true : false;
      }
      if (columnNumber == 3) {
        indexPaper.isHeader = index % 6 == 1 ? true : false;
      }
    }
    // if (!indexPaper.isHeader) {
    //   indexPaper.height = indexPaper.height;
    // }

    if (indexPaper.isHeader) {
      indexPaper.headerHeight = headerStyle == 1 ? 77 : 160;
      indexPaper.residueHeight = indexPaper.height - indexPaper.headerHeight;
      indexPaper.top = indexPaper.headerHeight;
    } else {
      indexPaper.headerHeight = 0;
      indexPaper.top = 0;
      indexPaper.residueHeight = indexPaper.height;
    }
    // A4纸的正反面都有装订线
    if (gutter == 1) {
      settingJson = JSON.parse(JSON.stringify(settingJson));
      if (index == 1) {
        settingJson.studentBarCode.left =
          settingJson.studentBarCode.left - gutterWidth / 2;
      }

      if (indexPaper.isHeader) {
        indexPaper.showBookbinding = true;
        indexPaper.gutterLocation = "1";
      }

      if (columnNumber == 2) {
        if (index % 4 == 0) {
          indexPaper.showBookbinding = true;
          indexPaper.gutterLocation = "2";
        }
        if (index % 4 == 3) {
          indexPaper.showBookbinding = false;
          indexPaper.gutterLocation = "2";
        }
        if (index % 4 == 2) {
          indexPaper.showBookbinding = false;
          indexPaper.gutterLocation = "1";
        }
      }
    }
    indexPaper.footer = {
      left: 364,
      top: indexPaper.paperMsg.paperHeight - indexPaper.paperMsg.viewTop + 15,
      width: new unitConversion().mmConversionPx(paperInfo.paperWidth),
      height: 15,
    };
    indexPaper.locationPoint = [
      {
        left: indexPaper.paperMsg.viewLeft,
        top: 40 - 15,
        width: 38,
        height: 15,
      },
      {
        left: indexPaper.paperMsg.paperWidth - indexPaper.paperMsg.viewLeft * 2,
        top: 40 - 15,
        width: 38,
        height: 15,
      },
      {
        left: indexPaper.paperMsg.viewLeft,
        top: indexPaper.paperMsg.paperHeight - indexPaper.paperMsg.viewTop + 15,
        width: 38,
        height: 15,
      },
      {
        left: indexPaper.paperMsg.paperWidth - indexPaper.paperMsg.viewLeft * 2,
        top: indexPaper.paperMsg.paperHeight - indexPaper.paperMsg.viewTop + 15,
        width: 38,
        height: 15,
      },
    ];
    indexPaper.pdfLocationPoint = [
      { left: indexPaper.paperMsg.viewLeft, top: 40, width: 38, height: 15 },
      {
        left: indexPaper.paperMsg.paperWidth - indexPaper.paperMsg.viewLeft * 2,
        top: 40,
        width: 38,
        height: 15,
      },
      {
        left: indexPaper.paperMsg.viewLeft,
        top: indexPaper.paperMsg.paperHeight - indexPaper.paperMsg.viewTop + 15,
        width: 38,
        height: 15,
      },
      {
        left: indexPaper.paperMsg.paperWidth - indexPaper.paperMsg.viewLeft * 2,
        top: indexPaper.paperMsg.paperHeight - indexPaper.paperMsg.viewTop + 15,
        width: 38,
        height: 15,
      },
    ];
  }
  if (indexPaper.isHeader) {
    indexPaper.locationPoint[0].width = 15;
    // indexPaper.locationPoint[0].top -= 15;
    // indexPaper.locationPoint[1].top -= 15;
    indexPaper.locationPoint[1].width = 15;
    indexPaper.locationPoint[1].left += 23;
  }
  let settingObj = getStore({ name: "settingObj" });
  Object.assign(indexPaper, settingObj, settingJson);
  return JSON.parse(JSON.stringify(indexPaper));
}
